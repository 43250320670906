import { useState, useEffect } from 'react'

import { PaginationParameters, PaginationResponse } from '@packages/interfaces'

import { apiFetch } from '../utils'

export function usePaginationApi<T>({
  endpoint,
  limit,
  otherQueryParams,
  disabled = false,
}: {
  endpoint: string
  limit?: number
  otherQueryParams?: { [key: string]: any }
  disabled?: boolean
}) {
  const [paginatedData, setPaginatedData] = useState<T[][]>([])
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false)
  const [cursor, setCursor] = useState<string | bigint>()
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [loading, setLoading] = useState(false) // anytime data is fetched this is true
  const [newPageLoading, setNewPageLoading] = useState(!disabled) // only true when new page is being fetched
  const [error, setError] = useState(null)
  const [paginationOptions, setPaginationOptions] = useState<PaginationParameters>({ limit })

  async function fetchData(newPaginationOptions: PaginationParameters) {
    setLoading(true)
    try {
      const queryParams = {
        ...(newPaginationOptions?.cursor && { cursor: newPaginationOptions.cursor }),
        ...(newPaginationOptions?.limit && { limit: newPaginationOptions.limit }),
        ...otherQueryParams,
      }

      const response: PaginationResponse<T> = await apiFetch<PaginationResponse<T>>(
        endpoint,
        queryParams
      )
      return response
    } catch (error: any) {
      console.error(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateExistingData() {
    if (loading || newPageLoading || disabled) return
    const newDataResponse = await fetchData({ limit: paginationOptions.limit })
    if (!newDataResponse) return

    setPaginatedData([newDataResponse?.data])
    setCursor(newDataResponse.nextCursor)
    setHasNextPage(newDataResponse.data.length > 0)
  }

  async function fetchNextPage() {
    if (!newPageLoading || disabled) return

    if (!initialDataLoaded) {
      setNewPageLoading(true)
    }
    setInitialDataLoaded(true)

    if (hasNextPage) {
      const response = await fetchData(paginationOptions)
      setNewPageLoading(false)
      if (!response) return

      setPaginatedData([...paginatedData, response.data])
      setCursor(response.nextCursor)
      setHasNextPage(response.data.length > 0)
    }

    setNewPageLoading(false)
  }

  useEffect(() => {
    fetchNextPage()
  }, [paginationOptions])

  useEffect(() => {
    updateExistingData()
  }, [disabled, endpoint, otherQueryParams])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchPage = async (page: number, forceFetch: boolean = false) => {
    if ((paginatedData?.length ?? 0) < page + 1 && !newPageLoading && (!error || forceFetch)) {
      setNewPageLoading(true)
      const nextPageParams = {
        ...paginationOptions,
        cursor: cursor,
      }
      setPaginationOptions(nextPageParams)
    }
  }

  return {
    paginatedData,
    loading,
    newPageLoading,
    error,
    fetchPage,
    hasNextPage,
    forceRefresh: updateExistingData,
    initialDataLoaded,
  }
}

// Usage example:
// const endpoint = 'https://api.example.com/data';
// const { data, loading, error, fetchPage } = usePaginationApi(endpoint);
// Call fetchPage to load the next page of data
