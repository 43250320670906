import { RuneDetails } from '@packages/interfaces'
import { bigIntToDecimal } from '@packages/utils'

import { formatPercent } from 'src/shared/utils'

import { MintDetails, MintState } from '../interfaces'

function isMintedOut({ runeDetails }: { runeDetails: RuneDetails }) {
  const isMintedOut = runeDetails.numberOfMints >= (runeDetails.maxMints ?? 0n)
  return isMintedOut
}

export function getMintState({
  blockTip,
  runeDetails,
}: {
  blockTip?: number
  runeDetails: RuneDetails
}) {
  let mintState: MintState = 'Not Mintable'

  if (runeDetails.amountPremined >= runeDetails.maximumSupply) {
    return 'Premined'
  }

  if (blockTip && runeDetails.isMintable) {
    if (isMintedOut({ runeDetails })) {
      mintState = 'Minted Out'
    } else if (
      runeDetails.mintCloseBlockHeight &&
      blockTip >= Number(runeDetails.mintCloseBlockHeight)
    ) {
      mintState = 'Ended'
    } else if (
      runeDetails.mintOpenBlockHeight &&
      blockTip <= Number(runeDetails.mintOpenBlockHeight)
    ) {
      mintState = 'Not Started'
    } else {
      mintState = 'Minting'
    }
  }

  return mintState
}

export function getMintDetails({
  runeDetails,
  blockTip,
}: {
  runeDetails?: RuneDetails
  blockTip?: number
}): MintDetails | undefined {
  if (!runeDetails) {
    return
  }

  const hasOpenBlockHeight = !!runeDetails?.mintOpenBlockHeight
  const hasClosedBlockHeight = !!runeDetails?.mintCloseBlockHeight

  const isInfiniteMintTerm = !hasClosedBlockHeight

  let mintStarted = !hasOpenBlockHeight
  let startsInBlocks = 0

  let mintEnded = false
  let endsInBlocks = 0

  if (blockTip) {
    if (runeDetails?.mintOpenBlockHeight) {
      const firstMintableBlock = Number(runeDetails.mintOpenBlockHeight) - 1

      if (blockTip >= firstMintableBlock) {
        mintStarted = true
      } else {
        mintStarted = false
        startsInBlocks = firstMintableBlock - blockTip
      }
    }

    if (runeDetails?.mintCloseBlockHeight) {
      const lastMintableBlock = Number(runeDetails.mintCloseBlockHeight) - 1

      if (blockTip >= lastMintableBlock) {
        mintEnded = true
      } else {
        endsInBlocks = lastMintableBlock - blockTip
      }
    }
  }

  const maxSupply = bigIntToDecimal(
    mintEnded ? runeDetails.numberOfMints : runeDetails.maximumSupply
  )

  const mintedAmount = runeDetails.numberOfMints * (runeDetails.runesAmountPerMint ?? 0n)
  const mintedPercent = runeDetails.maxMints
    ? bigIntToDecimal(runeDetails.numberOfMints)
        .div(bigIntToDecimal(mintEnded ? runeDetails.numberOfMints : runeDetails.maxMints))
        .mul(100)
        .toNumber()
    : 0

  const mintedPercentWithPendingMints = runeDetails.maxMints
    ? bigIntToDecimal(runeDetails.numberOfMints + (mintEnded ? 0n : runeDetails.pendingMints ?? 0n))
        .div(bigIntToDecimal(mintEnded ? runeDetails.numberOfMints : runeDetails.maxMints))
        .mul(100)
        .toNumber()
    : 0

  let mintedPercentOverMaxSupply = 0
  let burnedPercentOverMaxSupply = 0
  let preminedPercentOverMaxSupply = 0
  let pendingMintPercentOverMaxSupply = 0

  if (maxSupply.greaterThan(0)) {
    mintedPercentOverMaxSupply = bigIntToDecimal(mintedAmount).div(maxSupply).mul(100).toNumber()

    burnedPercentOverMaxSupply = bigIntToDecimal(runeDetails.amountBurned)
      .div(maxSupply)
      .mul(100)
      .toNumber()

    preminedPercentOverMaxSupply = bigIntToDecimal(runeDetails.amountPremined)
      .div(maxSupply)
      .mul(100)
      .toNumber()

    pendingMintPercentOverMaxSupply = bigIntToDecimal(runeDetails.pendingMints ?? 0n)
      .div(maxSupply)
      .mul(100)
      .toNumber()
  }

  const isAllPremined = runeDetails.amountPremined >= runeDetails.maximumSupply

  let formattedMintedPercent = '0%'
  if (!isAllPremined) {
    formattedMintedPercent = formatPercent(mintedPercent)
  }

  let mintsRemaining = 0n
  if (runeDetails.maxMints && !mintEnded) {
    mintsRemaining = runeDetails.maxMints - runeDetails.numberOfMints
  }

  return {
    isInfiniteMintTerm,
    isAllPremined,
    isMintable: runeDetails.isMintable,
    isMintedOut: isMintedOut({ runeDetails }),
    mintedPercent,
    mintedPercentWithPendingMints,
    formattedMintedPercent,
    mintedPercentOverMaxSupply,
    burnedPercentOverMaxSupply,
    preminedPercentOverMaxSupply,
    pendingMintPercentOverMaxSupply,
    mintOpenBlockHeight: runeDetails.mintOpenBlockHeight,
    mintCloseBlockHeight: runeDetails.mintCloseBlockHeight,
    mintEnded,
    mintStarted,
    startsInBlocks,
    endsInBlocks,
    mintedAmount,
    mintsRemaining,
    mintState: getMintState({ runeDetails, blockTip }),
  }
}
