import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import Decimal from 'decimal.js-light'
import InfoIcon from '@mui/icons-material/Info'
import { toast } from 'react-toastify'

import { Rune, RuneOutpointDetails } from '@packages/interfaces'
import { formatBtc, formatRuneName, formatUsd } from '@packages/utils'
import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'

import {
  Drawer,
  DrawerCard,
  ErrorMessage,
  Accordion,
  PaginatedTable,
  Checkbox,
  Divider,
  DrawerColumn,
  DrawerBoldColumn,
  DrawerTitle,
  DrawerTieredContent,
  DrawerRow,
  DrawerButtons,
  DrawerCancelButton,
  DrawerConfirmButton,
  LinkPageToast,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { RuneOutpointExternalLink, RunesAmountDisplay, formatRunesAmount } from 'src/runes'
import { useWalletContext } from 'src/wallet'
import { useDebounce } from 'src/shared/hooks'
import { buildAccountDetailsUrl } from 'src/pages'
import { AddressInput, NetworkFeeSelector } from 'src/web3'
import { ACCOUNT_DETAILS_TABS } from 'src/account'
import { chunkArray, hexToRgb } from 'src/shared/utils'
import {
  GA_AbandonSwap,
  GA_ConfirmSwap,
  GA_ExecuteSwap,
  GA_InitiateSwap,
} from 'src/shared/utils/analytics'

import { calculatePrice } from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { DISABLE_WRITES } from 'src/settings'

interface Props {
  isOpen: boolean
  onClose: ({ clearSelectedOutpoints }: { clearSelectedOutpoints?: boolean }) => void
  rune: Rune
  outpoints: RuneOutpointDetails[]
  satsAmount?: bigint
  runesAmount?: bigint
  gaEventId: string
  setGaEventId: (gaEventId: string) => void
}

const BIG_INT_ZERO = BigInt(0)
const OUTPOINTS_PER_PAGE = 10

export function SellRunesDrawer({
  isOpen,
  onClose,
  outpoints,
  satsAmount: _satsAmount,
  runesAmount,
  rune,
  gaEventId,
  setGaEventId,
}: Props) {
  const { btcPrice, sellRunes, paymentAddress, runesAddress } = useWalletContext()

  const [satsAmount, setSatsAmount] = useState(_satsAmount ?? BIG_INT_ZERO)
  const [runesAmountTotal, setRunesAmountTotal] = useState<bigint>(0n)

  const [showProceedsAddressInput, setShowProceedsAddressInput] = useState(false)
  const [isValidProceedsAddress, setIsValidProceedsAddress] = useState(true)
  const [proceedsAddress, setProceedsAddress] = useState('')

  const [isDetailsLoading, setIsDetailsLoading] = useState(false)

  const [paginatedOutpoints, setPaginatedOutpoints] = useState<RuneOutpointDetails[][]>([[]])
  const [orderDetails, setOrderDetails] = useState<{
    satsTotal: bigint
    usdTotal: number
    priceSats: string
  }>({ satsTotal: 0n, usdTotal: 0, priceSats: '0' })
  const [loadingText, setLoadingText] = useState<string>()
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (_satsAmount) setSatsAmount(_satsAmount)
  }, [_satsAmount])

  useEffect(() => {
    let total = outpoints.reduce((acc, curr) => {
      return acc + curr.amount
    }, 0n)
    total = !!runesAmount && total > runesAmount ? runesAmount : total

    setRunesAmountTotal(total)
    setPaginatedOutpoints(chunkArray(outpoints, OUTPOINTS_PER_PAGE))
  }, [outpoints, runesAmount])

  useDebounce(
    async () => {
      if (satsAmount === 0n) return
      try {
        setError(undefined)
        setLoadingText('Recalculating...')
        setIsDetailsLoading(true)

        const priceSats = calculatePrice({ runesAmount: runesAmountTotal, satsAmount, rune })
        setOrderDetails({
          satsTotal: satsAmount,
          usdTotal: new Decimal(`${satsAmount}`).mul(btcPrice?.satPriceUsd ?? 0).toNumber(),
          priceSats,
        })
        validateForm()
      } catch (error) {
        console.error(error)
        setError((error as Error).message)
      } finally {
        setLoadingText(undefined)
        setIsDetailsLoading(false)
      }
    },
    [outpoints, satsAmount, btcPrice, runesAmountTotal],
    250
  )

  function validateForm() {
    let validationError: string | undefined
    setError(undefined)
    if (satsAmount < SATOSHI_DUST_THRESHOLD) {
      validationError = 'BTC amount is below the dust threshold'
    } else if (!proceedsAddress) {
      validationError = 'Enter a valid proceeds address'
    } else if (!runesAddress) {
      validationError = 'No runes address found'
    }
    setError(validationError)

    return validationError
  }

  useEffect(() => {
    validateForm()
  }, [satsAmount])

  useEffect(() => {
    if (paymentAddress) {
      setProceedsAddress(paymentAddress.addrString)
    }
  }, [paymentAddress?.addrString])

  useEffect(() => {
    if (isOpen) {
      const eventId = GA_InitiateSwap(
        window,
        gaEventId,
        rune.runeName,
        runesAmountTotal,
        'sell',
        orderDetails.satsTotal
      )
      if (gaEventId == '') setGaEventId(eventId)
    }
  }, [isOpen])

  function resetForm() {
    setGaEventId('')
    setError(undefined)
    setSatsAmount(0n)
    setSelectedNetworkFee(0)
    setShowProceedsAddressInput(false)
  }

  async function onConfirmClick() {
    GA_ConfirmSwap(
      window,
      gaEventId,
      rune.runeName,
      runesAmountTotal,
      'sell',
      selectedNetworkFee,
      orderDetails.satsTotal
    )
    try {
      const validationError = validateForm()
      if (validationError != undefined) {
        GA_AbandonSwap(
          window,
          gaEventId,
          rune.runeName,
          runesAmountTotal,
          'sell',
          selectedNetworkFee,
          orderDetails.satsTotal
        )
        return
      }

      setLoadingText('Generating tx')

      const response = await sellRunes({
        rune,
        proceeds: satsAmount,
        runeOutpoints: outpoints,
        proceedsAddress,
        sellAmount: runesAmountTotal,
        fee: BigInt(selectedNetworkFee),
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'transfer-wallet-prompt':
              setLoadingText('1/2 Tx: Preparing Runes')
              break
            case 'sell-wallet-prompt-boxed':
              setLoadingText('2/2 Tx: Sell Order')
              break
            case 'sell-wallet-prompt-not-boxed':
              setLoadingText('See wallet')
              break
            case 'api-submit':
              setLoadingText('Submitting order')
              break
          }
        },
      })

      if (response.success) {
        GA_ExecuteSwap(
          window,
          gaEventId,
          rune.runeName,
          runesAmountTotal,
          'sell',
          selectedNetworkFee,
          orderDetails.satsTotal
        )

        toast(
          <LinkPageToast
            message={'Order submitted.'}
            buttonMessage={'View order'}
            reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
            reactRouterSearch={`?tab=${ACCOUNT_DETAILS_TABS.openOrders}`}
          />,
          { toastId: Date.now() }
        )

        resetForm()
        onClose({ clearSelectedOutpoints: true })
      } else {
        GA_AbandonSwap(
          window,
          gaEventId,
          rune.runeName,
          runesAmountTotal,
          'sell',
          selectedNetworkFee,
          orderDetails.satsTotal
        )
      }
    } catch (error: any) {
      console.error(error)
      setError(error.message)
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    GA_AbandonSwap(
      window,
      gaEventId,
      rune.runeName,
      runesAmountTotal,
      'sell',
      selectedNetworkFee,
      orderDetails.satsTotal
    )
    setGaEventId('')
    setError(undefined)
    onClose({})
  }

  function handleOnCancel() {
    GA_AbandonSwap(
      window,
      gaEventId,
      rune.runeName,
      runesAmountTotal,
      'sell',
      selectedNetworkFee,
      orderDetails.satsTotal
    )
    setLoadingText(undefined)
  }

  const buttonText = loadingText ?? 'Confirm Order'
  const isButtonDisabled = !satsAmount || !proceedsAddress || !isValidProceedsAddress || !!error
  const outpointsTotal = outpoints.reduce((total, outpoint) => total + outpoint.amount, 0n)
  const needBoxingTx = outpointsTotal !== runesAmountTotal || outpoints.length > 1

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Sell {formatRuneName(rune)}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Price</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={
                <>
                  &nbsp;
                  <PriceSatsDisplay
                    priceSats={orderDetails.priceSats}
                    runeSymbol={rune.runeSymbolChar}
                  />
                </>
              }
              subContent={<PriceUsdDisplay priceSats={orderDetails.priceSats} />}
              loading={isDetailsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>{formatRuneName(rune)}</DrawerBoldColumn>
          <RunesTotalColumn>
            -
            <RunesAmountDisplay
              rune={rune}
              runesAmount={runesAmountTotal}
              showPriceSymbol={false}
            />
          </RunesTotalColumn>
        </DrawerRow>
        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>Total BTC</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={<BtcTotal>+{formatBtc(orderDetails.satsTotal)}</BtcTotal>}
              subContent={<UsdTotal>&nbsp;{formatUsd({ usd: orderDetails.usdTotal })}</UsdTotal>}
              loading={isDetailsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
      </DrawerCard>
      <DrawerCard>
        <Accordion linkText='View Rune Outpoints'>
          <PaginatedTableWrapper
            columns={[
              {
                dataKey: 'runesAmount',
                label: 'Quantity',
                width: 60,
                formatter: ({ data: outpoint }) =>
                  formatRunesAmount({
                    rune,
                    runesAmount: outpoint.amount,
                  }),
              },
              {
                dataKey: 'outpointId',
                label: 'Tx',
                width: 40,
                formatter: ({ data: outpoint }) => (
                  <RuneOutpointExternalLink runeOutpoint={outpoint} />
                ),
              },
            ]}
            paginatedData={paginatedOutpoints}
            fetchPage={async () => {}}
            rowHeight={30}
            rowsPerPage={OUTPOINTS_PER_PAGE}
          />
        </Accordion>
      </DrawerCard>
      <DrawerCard>
        <DrawerRow>
          <DrawerBoldColumn>Proceeds Address</DrawerBoldColumn>
        </DrawerRow>
        {!showProceedsAddressInput && (
          <DrawerRow>
            <AddressColumn>{proceedsAddress}</AddressColumn>
          </DrawerRow>
        )}
        {showProceedsAddressInput && (
          <DrawerRow>
            <AddressInput
              value={proceedsAddress}
              onChange={setProceedsAddress}
              isValid={isValidProceedsAddress}
              onIsValidChange={setIsValidProceedsAddress}
              helperText={!proceedsAddress ? 'Required' : undefined}
            />
          </DrawerRow>
        )}

        <DrawerRow>
          <AddressColumn>
            Use custom proceeds address
            <CheckboxWrapper
              checked={showProceedsAddressInput}
              onChange={function (checked: boolean): void {
                setShowProceedsAddressInput(checked)
              }}
              disabled={!isValidProceedsAddress}
            />
          </AddressColumn>
        </DrawerRow>
      </DrawerCard>
      {needBoxingTx && (
        <>
          <DrawerCard>
            <DrawerRow>
              <NetworkFeeSelector
                selectedFee={selectedNetworkFee}
                onChange={(fee) => setSelectedNetworkFee(fee)}
              />
            </DrawerRow>
          </DrawerCard>
          <DrawerCard>
            <InfoContainer>
              <InfoIcon />
              You will be prompted to sign 2 transactions. The first transaction will{' '}
              {outpoints.length > 1 ? 'combine' : 'split'} your runes. The second transaction will
              create the sell order.
            </InfoContainer>
          </DrawerCard>
        </>
      )}
      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => handleOnCancel()}>Cancel Tx</DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          onClick={onConfirmClick}
          loading={!!loadingText}
          disabled={isButtonDisabled || DISABLE_WRITES}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const RunesTotalColumn = styled(DrawerColumn)`
  gap: 0px;
  display: flex;
  color: ${COLORS.negative};
  font-weight: 700;
`

const BtcTotal = styled.div`
  font-weight: 700;
  color: ${COLORS.positive};
`

const UsdTotal = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-size: 14px;
`

const PaginatedTableWrapper = styled(PaginatedTable<RuneOutpointDetails>)`
  tfoot {
    display: none;
  }
  thead {
    position: relative !important;
  }
`

const InfoContainer = styled.div`
  font-weight: 300;
  font-size: 15px;
  display: flex;
  gap: 5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
  }
`

const AddressColumn = styled(DrawerColumn)`
  font-size: 14px;
  display: flex;
  align-items: center;
  span {
    width: 45px;
    padding: 0px;
  }
  svg {
    height: 30px;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 12px;
  }
`

const CheckboxWrapper = styled(Checkbox)`
  svg {
    width: 25px;
    height: 25px;
  }
`
