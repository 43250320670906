import { hex } from '@scure/base'
import * as ordinals from 'micro-ordinals'
import { secp256k1 } from '@noble/curves/secp256k1'
import { InputToSign } from 'sats-connect'

import * as btc from '@packages/scure-btc-signer'
import * as wasm from '@packages/rune-wasm'
import { EtchingParams, SpacedRune, Terms, U128 } from '@packages/rune-wasm'
import * as payment from '@packages/scure-btc-signer/payment'
import * as psbt from '@packages/scure-btc-signer/psbt'
import { Address, OutScript, RuneInput, selectRuneUTXO, WIF } from '@packages/scure-btc-signer'
import { calculateFeeFromBps } from '@packages/utils'
import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'

import { TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'
import { base64ToHex } from 'src/shared/utils'

import { AddressDetails, BtcSignerNetwork, BtcSignerOutput, RuneUtxoOutpoint } from '../interfaces'
import { getCleanUtxos, utxoToInput } from './cleanUtxos'
import { getRuneUtxos } from './runeUtxos.ts'

export function finalizeTx(signedPsbtBase64: string) {
  const hex = base64ToHex(signedPsbtBase64)
  const bytes = Buffer.from(hex, 'hex')
  const finalizedTx = btc.Transaction.fromPSBT(bytes)
  finalizedTx.finalize()

  return finalizedTx
}

export async function buildMintTx({
  paymentAddress,
  recipientAddress,
  runeId,
  network,
  fee,
}: {
  paymentAddress: AddressDetails
  recipientAddress: string
  runeId: string
  network: BtcSignerNetwork
  fee: bigint
}): Promise<btc.Transaction> {
  if (!paymentAddress || !recipientAddress || !runeId) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('runeId', runeId)
    throw new Error('bad params')
  }
  const mintRunestone = wasm.encode_mint(runeId, 0)

  const utxos = await getCleanUtxos(paymentAddress)
  const outputs: BtcSignerOutput[] = [
    { address: recipientAddress, amount: SATOSHI_DUST_THRESHOLD },
    { script: mintRunestone, amount: 0n },
  ]

  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: paymentAddress.addrString,
    network: network,
    feePerByte: fee,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  const tx = selected.tx

  if (tx === undefined) {
    throw new Error('Unable to generate transaction')
  }

  console.log('tx', tx)
  return tx
}

export interface BuildBulkMintResult {
  parentTx: btc.Transaction
  childTxs: btc.Transaction[]
  tempKey: Uint8Array
  tempKeyAddress: string
}

export interface BuildBulkMintSerializedResult {
  parentTxSignedBase64: string
  childTxsBase64: string[]
  tempKey: Uint8Array
  tempKeyAddress: string
}

export interface BuildBulkMintParams {
  paymentAddress: AddressDetails
  recipientAddress: string
  serviceFeeReceiverAddress: string
  serviceFeeMintBps: number
  runeId: string
  network: BtcSignerNetwork
  fee: bigint
  estimatedTotalNetworkFee: bigint
  childEstimatedVsize?: bigint
  quantity: bigint
}

export async function buildBulkMintTxs({
  paymentAddress,
  recipientAddress,
  serviceFeeReceiverAddress,
  serviceFeeMintBps,
  runeId,
  network,
  fee,
  estimatedTotalNetworkFee,
  childEstimatedVsize,
  quantity,
}: BuildBulkMintParams): Promise<BuildBulkMintResult> {
  if (!paymentAddress || !recipientAddress || !runeId) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('runeId', runeId)
    throw new Error('Bad params')
  }

  const MINT_RECOVERY_KEY = `${network === btc.NETWORK ? 'mainnet' : 'testnet'}-mint-recovery`
  const pkFromStorage = localStorage.getItem(MINT_RECOVERY_KEY)
  const childPrivKey =
    pkFromStorage !== null ? WIF(network).decode(pkFromStorage) : secp256k1.utils.randomPrivateKey()
  const wif = WIF(network).encode(childPrivKey)
  localStorage.setItem(MINT_RECOVERY_KEY, wif)

  const childPubKey = btc.utils.pubSchnorr(childPrivKey)
  const childP2 = btc.p2tr(childPubKey, undefined, network)
  const childMintCost =
    fee * (childEstimatedVsize ?? BigInt(TRX_VIRTUAL_SIZE_BYTES.mint)) + SATOSHI_DUST_THRESHOLD

  const mintRunestone = wasm.encode_mint(runeId, 0)

  const utxos = await getCleanUtxos(paymentAddress)
  const outputs: BtcSignerOutput[] = []
  for (let i = 0n; i < quantity; i++) {
    outputs.push({ script: childP2.script, amount: childMintCost })
  }

  const serviceFee = calculateFeeFromBps(estimatedTotalNetworkFee, serviceFeeMintBps)
  if (serviceFee !== 0n) {
    // TODO: consider moving the service fee output to the child mints instead of the parent
    outputs.push({
      address: serviceFeeReceiverAddress,
      amount: serviceFee,
    })
  }
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: paymentAddress.addrString,
    network: network,
    feePerByte: fee,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  const parentTx = selected.tx

  if (parentTx === undefined) {
    throw new Error('Unable to generate transaction')
  }

  const childTxs: btc.Transaction[] = []
  for (let i = 0; i < Number(quantity); i++) {
    const childTx = new btc.Transaction({ allowUnknownOutputs: true })
    childTx.addInput({
      ...childP2,
      txid: '0000000000000000000000000000000000000000000000000000000000000000',
      index: i,
      sequence: 0xfffffff0, // allow RBF
      witnessUtxo: {
        script: childP2.script,
        amount: BigInt(childMintCost),
      },
    })
    childTx.addOutputAddress(recipientAddress, SATOSHI_DUST_THRESHOLD, network)
    childTx.addOutput({ script: mintRunestone, amount: 0n })
    childTxs.push(childTx)
  }
  if (!childP2.address) {
    throw new Error('Unable to generate child address')
  }

  return {
    parentTx: parentTx,
    childTxs: childTxs,
    tempKey: childPrivKey,
    tempKeyAddress: childP2.address,
  }
}

export interface SignBulkMintResult {
  parentTx: btc.Transaction
  childTxs: btc.Transaction[]
  tempKey: Uint8Array
}

export interface SignBulkMintSerializedResult {
  parentTxBase64: string
  childTxsBase64: string[]
  tempKey: Uint8Array
}

export async function signBulkMintTxs(bulk: BuildBulkMintResult): Promise<SignBulkMintResult> {
  bulk.parentTx.finalize()
  const res: SignBulkMintResult = { parentTx: bulk.parentTx, childTxs: [], tempKey: bulk.tempKey }
  for (let i = 0; i < bulk.childTxs.length; i++) {
    const tx = bulk.childTxs[i]
    const input = tx.getInput(0)
    tx.updateInput(0, { ...input, txid: bulk.parentTx.id })
    tx.signIdx(bulk.tempKey, 0, [btc.SigHash.DEFAULT], new Uint8Array(32))
    tx.finalize()
    res.childTxs.push(tx)
  }
  return res
}

export async function buildSellPsbt({
  runeAddressDetails,
  proceedsAddress,
  proceedsAmount,
  runeId,
  runeUtxo, // boxed output, selling entire utxo
  network,
}: {
  runeAddressDetails: AddressDetails
  proceedsAddress: string
  proceedsAmount: bigint
  runeUtxo: RuneUtxoOutpoint
  runeId: string
  network: BtcSignerNetwork
}) {
  if (!runeAddressDetails || !proceedsAddress || !runeId) {
    console.log('runeAddress', runeAddressDetails)
    console.log('proceedsAddress', proceedsAddress)
    console.log('runeId', runeId)
    console.log('bad params')
    return
  }

  const tx = new btc.Transaction()

  const runeInput = utxoToInput(runeAddressDetails.p2, runeUtxo.utxo)
  runeInput.sighashType = btc.SigHash.SINGLE_ANYONECANPAY

  tx.addInput(runeInput)
  tx.addOutputAddress(proceedsAddress, proceedsAmount, network)

  return tx
}

export interface EtchingCommit {
  revealAmount: bigint
  txid: string | undefined
  commitTx: btc.Transaction
  revealScript: payment.P2TROut
  revealKey: Uint8Array
}

export interface EtchingCommitOpts {
  paymentAddress: AddressDetails
  recipientAddress: AddressDetails
  network: BtcSignerNetwork
  fee: bigint
  params: JsEtchingParams
}

export async function buildEtchingCommit(opts: EtchingCommitOpts): Promise<EtchingCommit> {
  if (!opts.paymentAddress || !opts.recipientAddress) {
    throw new Error('Invalid transaction parameters')
  }
  const { paymentAddress, recipientAddress, network, fee: feeRate } = opts

  if (!paymentAddress || !recipientAddress || !opts.params.rune) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('opts.params.rune', opts.params.rune)
    console.log('bad params')
    throw new Error('Wallet not connected')
  }

  const revealPrivKey = secp256k1.utils.randomPrivateKey()
  // private key: ${red}${WIF(net).encode(res[name])}${reset}
  const revealPubKey = btc.utils.pubSchnorr(revealPrivKey)
  const customScripts = [ordinals.OutOrdinalReveal]

  const rune = wasm.SpacedRune.from_string(opts.params.rune)
  const runeVal = rune.to_bigint()
  rune.free()

  const inscription: ordinals.Inscription = {
    tags: {
      rune: runeVal,
    },
    body: new Uint8Array(),
  }

  const revealPayment = btc.p2tr(
    undefined,
    ordinals.p2tr_ord_reveal(revealPubKey, [inscription]),
    network,
    false,
    customScripts
  )

  if (revealPayment.address === undefined) {
    throw new Error('Unable to create reveal address')
  }

  // Value for reveal fee; could more intelligently calculate a precise fee by estimating reveal cost
  const commitAmount = 300n * feeRate

  const utxo = await getCleanUtxos(paymentAddress)
  const commitOutputs: BtcSignerOutput[] = [
    { address: revealPayment.address, amount: commitAmount },
  ]
  const _commitSelector = _createCommitTx(
    utxo,
    commitOutputs,
    paymentAddress.addrString,
    network,
    feeRate
  )
  if (!_commitSelector.tx) {
    throw new Error('Unable to create commit transaction')
  }
  const _commitTx = _commitSelector.tx

  return <EtchingCommit>{
    commitTx: _commitTx,
    revealScript: revealPayment,
    revealAmount: commitAmount,
    revealKey: revealPrivKey,
    txid: undefined,
  }
}

export interface EtchingReveal {
  revealTx: btc.Transaction
}

export interface EtchingRevealOpts {
  paymentAddress: AddressDetails
  recipientAddress: AddressDetails
  network: BtcSignerNetwork
  fee: bigint
  params: JsEtchingParams
  etchingCommit: EtchingCommit
}

export async function buildEtchingReveal(opts: EtchingRevealOpts): Promise<EtchingReveal> {
  if (!opts.paymentAddress || !opts.recipientAddress) {
    throw new Error('Invalid transaction parameters')
  }
  const { paymentAddress, recipientAddress, network, fee: feeRate } = opts

  if (!paymentAddress || !recipientAddress || !opts.params.rune) {
    console.log('paymentAddress', paymentAddress)
    console.log('recipientAddress', recipientAddress)
    console.log('opts.params.rune', opts.params.rune)
    console.log('bad params')
    throw new Error('Wallet not connected')
  }

  const etchingParams = _etchingParamsToRust(opts.params)
  const etchingRunestone = wasm.encode_etching(etchingParams)
  // etchingParams.free()

  const outputs: BtcSignerOutput[] = [
    { address: recipientAddress.addrString, amount: 546n },
    { script: etchingRunestone, amount: 0n },
  ]

  const inputs: psbt.TransactionInputUpdate[] = [
    {
      ...opts.etchingCommit.revealScript,
      txid: opts.etchingCommit.txid,
      index: 0,
      witnessUtxo: {
        script: opts.etchingCommit.revealScript.script,
        amount: opts.etchingCommit.revealAmount,
      },
    },
  ]

  const _revealSelector = _createRevealTx(
    inputs,
    outputs,
    paymentAddress.addrString,
    network,
    feeRate
  )
  // const revealFee = _revealTx.fee
  if (!_revealSelector || !_revealSelector.tx) {
    throw new Error('Unable to create reveal')
  }

  const _revealTx = _revealSelector.tx
  _revealTx.signIdx(opts.etchingCommit.revealKey, 0)
  _revealTx.finalizeIdx(0)

  return <EtchingReveal>{
    revealTx: _revealTx,
  }
}

function _createCommitTx(
  utxos: psbt.TransactionInputUpdate[],
  outputs: BtcSignerOutput[],
  changeAddress: string,
  network: BtcSignerNetwork,
  feeRate: bigint
) {
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: changeAddress,
    network: network,
    feePerByte: feeRate,
    allowUnknownOutputs: true, // op_return
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  return selected
}

function _createRevealTx(
  utxos: psbt.TransactionInputUpdate[],
  outputs: BtcSignerOutput[],
  changeAddress: string,
  network: BtcSignerNetwork,
  feeRate: bigint
) {
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: changeAddress,
    network: network,
    feePerByte: feeRate,
    allowUnknownOutputs: true, // op_return
    customScripts: [ordinals.OutOrdinalReveal],
  })
  if (selected === undefined) {
    throw new Error('Insufficient funds')
  }

  return selected
}

export interface JsEtchingParams {
  divisibility: number // u8
  premine: bigint // u128
  premine_vout: number | undefined // u32
  rune: string | undefined
  symbol: string | undefined
  turbo: boolean

  has_terms: boolean
  terms_cap: bigint | undefined // u128
  terms_amount: bigint | undefined // u128
  terms_absolute_open_height: bigint | undefined // u64
  terms_absolute_close_height: bigint | undefined // u64
  terms_relative_open_height: bigint | undefined // u64
  terms_relative_close_height: bigint | undefined // u64
}

function _etchingParamsToRust(params: JsEtchingParams): EtchingParams {
  const etchingParams = new EtchingParams()
  etchingParams.divisibility = params.divisibility
  etchingParams.premine = U128.from_bigint(params.premine)
  etchingParams.premine_vout = params.premine_vout
  etchingParams.rune = params.rune ? SpacedRune.from_string(params.rune) : undefined
  etchingParams.symbol = params.symbol
  etchingParams.turbo = params.turbo

  if (params.has_terms) {
    const terms = new Terms()
    terms.cap = params.terms_cap ? U128.from_bigint(params.terms_cap) : undefined
    terms.amount = params.terms_amount ? U128.from_bigint(params.terms_amount) : undefined
    terms.absolute_open_height = params.terms_absolute_open_height
    terms.absolute_close_height = params.terms_absolute_close_height
    terms.relative_open_height = params.terms_relative_open_height
    terms.relative_close_height = params.terms_relative_close_height
    etchingParams.terms = terms
  }

  return etchingParams
}

export async function getRuneTransactionInputs(
  runesAddress: AddressDetails,
  runeId: string,
  transferAmount: bigint
) {
  const runeOutpointAmounts = await getRuneUtxos({
    address: runesAddress,
    runeId,
    desiredAmount: transferAmount,
  })
  const runeUnspentInputs: RuneInput[] = runeOutpointAmounts.map((outpoint) => {
    return {
      amount: outpoint.amount,
      txid: outpoint.txId,
      index: Number(outpoint.vout),
    }
  })
  const runeSelectedInputs = selectRuneUTXO(
    runeUnspentInputs,
    [{ amount: transferAmount }],
    'default'
  )

  if (runeSelectedInputs === undefined) {
    throw new Error('Insufficient rune funds')
  }

  const runeSelection = await Promise.all(
    runeSelectedInputs.inputs.map(async (input) => {
      const txid = input.txid instanceof Uint8Array ? hex.encode(input.txid) : input.txid

      const runeUtxo: RuneUtxoOutpoint = {
        runeId: runeId,
        amount: input.amount,
        utxo: {
          value: Number(
            runeOutpointAmounts.find(
              (utxo) => utxo.txId === txid && Number(utxo.vout) === input.index
            )?.value ?? 0
          ),
          status: {
            confirmed: false,
            block_height: 0,
            block_hash: '',
            block_time: 0,
          },
          txid,
          vout: input.index,
        },
      }

      return runeUtxo
    })
  )

  return runeSelection
}

export interface CreateRuneTransferParams {
  runesAddress: AddressDetails
  paymentAddress: AddressDetails
  recipientAddress: string
  runeId: string
  transferAmount: bigint
  runeUtxoOutpoints?: RuneUtxoOutpoint[]
  network: BtcSignerNetwork
  fee: bigint
}

export interface RuneTransferTx {
  tx: btc.Transaction
  inputsToSign: InputToSign[]
  spendingUtxos: RuneUtxoOutpoint[]
}

export async function createRuneTransferTx({
  runesAddress,
  paymentAddress,
  recipientAddress,
  runeId,
  transferAmount,
  runeUtxoOutpoints,
  network,
  fee,
}: CreateRuneTransferParams): Promise<RuneTransferTx> {
  let outpoints: RuneUtxoOutpoint[]
  if (runeUtxoOutpoints && runeUtxoOutpoints.length > 0) {
    outpoints = runeUtxoOutpoints
  } else {
    outpoints = await getRuneTransactionInputs(runesAddress, runeId, transferAmount)
  }

  const runeTransactionInputs: psbt.TransactionInputUpdate[] = outpoints.map((runeUtxo) =>
    utxoToInput(runesAddress.p2, runeUtxo.utxo)
  )

  const toIndex = 0
  const changeIndex = 1

  const transferRunestone = wasm.encode_simple_transfer(
    runeId,
    transferAmount,
    toIndex,
    changeIndex
  )

  const utxos = await getCleanUtxos(paymentAddress)
  const outputs: BtcSignerOutput[] = [
    { address: recipientAddress, amount: SATOSHI_DUST_THRESHOLD },
    { address: runesAddress.addrString, amount: SATOSHI_DUST_THRESHOLD },
    { script: transferRunestone, amount: 0n },
  ]
  const selected = btc.selectUTXO(utxos, outputs, 'default', {
    bip69: false,
    changeAddress: paymentAddress.addrString,
    network: network,
    feePerByte: fee,
    allowUnknownOutputs: true, // op_return
    requiredInputs: runeTransactionInputs,
  })
  if (selected === undefined) {
    throw new Error('Insufficient utxo')
  }

  const tx = selected.tx

  if (tx === undefined) {
    throw new Error('Unable to generate tx')
  }

  const inputsToSign: InputToSign[] = []
  if (paymentAddress == runesAddress) {
    inputsToSign.push({
      address: paymentAddress.addrString,
      signingIndexes: new Array(tx.inputsLength).map((_, index) => index),
    })
  } else {
    const payIndices: number[] = []
    const runeIndices: number[] = []
    for (let i = 0; i < tx.inputsLength; i++) {
      const inputAddress = (selected.inputs[i] as any).address
      if (inputAddress === paymentAddress.p2.address) payIndices.push(i)
      if (inputAddress === runesAddress.p2.address) runeIndices.push(i)

      const witnessUtxoScript = selected?.inputs[i]?.witnessUtxo?.script
      if (witnessUtxoScript && !inputAddress) {
        try {
          const outputScriptAddress = Address(network).encode(OutScript.decode(witnessUtxoScript))

          if (outputScriptAddress === paymentAddress.p2.address) payIndices.push(i)
          if (outputScriptAddress === runesAddress.p2.address) runeIndices.push(i)
        } catch (error) {
          console.error('Error getting address from output script', error)
        }
      }
    }
    inputsToSign.push({
      address: paymentAddress.addrString,
      signingIndexes: payIndices,
    })
    inputsToSign.push({
      address: runesAddress.addrString,
      signingIndexes: runeIndices,
    })
  }

  console.log('tx', tx)
  console.log(
    'inputs',
    selected.inputs.map((input) => input.txid + ':' + input.index)
  )
  return { tx, inputsToSign, spendingUtxos: outpoints }
}

export function uint8ArrayEquals(a: Uint8Array, b: Uint8Array): boolean {
  return !!a && !!b && a.length === b.length && a.every((value, index) => value === b[index])
}
