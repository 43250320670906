import { styled } from 'styled-components'
import { useState } from 'react'

import { RuneBalance } from '@packages/interfaces'

import { Button } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { GA_InitiateTransfer } from 'src/shared/utils/analytics'

import { TransferRunesDrawer } from './TransferRunesDrawer'

interface Props {
  balance?: RuneBalance
  className?: string
}

export function TransferRunesButton({ balance, className }: Props) {
  const [isTransferDrawerOpen, setIsTransferDrawerOpen] = useState(false)
  const [gaEventId, setGaEventId] = useState('')

  function openTransferDrawer() {
    if (balance) setGaEventId(GA_InitiateTransfer(window, balance.runeName))

    setIsTransferDrawerOpen(true)
  }

  function closeTransferDrawer() {
    setIsTransferDrawerOpen(false)
  }

  if (!balance) {
    return (
      <TransferButton onClick={() => openTransferDrawer()} className={className}>
        Transfer
      </TransferButton>
    )
  }

  return (
    <>
      <TransferButton onClick={() => openTransferDrawer()} className={className}>
        Transfer
      </TransferButton>
      {isTransferDrawerOpen && (
        <TransferRunesDrawer
          isOpen={isTransferDrawerOpen}
          onClose={closeTransferDrawer}
          runeBalance={balance}
          eventId={gaEventId}
        />
      )}
    </>
  )
}

const TransferButton = styled(Button)`
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
`
