import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'
import { formatNumber } from '@packages/utils'

import { MultiPartProgressBar } from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { formatRunesAmount } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { hexToRgb } from 'src/shared/utils'

import { MintDetails } from '../interfaces'

interface Props {
  mintDetails: MintDetails
  runeDetails: RuneDetails
}

export function MintProgressBar({ mintDetails, runeDetails }: Props) {
  const isMobile = useIsMobile()

  if (!mintDetails) return

  const mintedPercentLessThanHalf =
    mintDetails.mintedPercentOverMaxSupply < 0.5 && mintDetails.mintedPercentOverMaxSupply > 0
  const preminedPercentLessThanHalf =
    mintDetails.preminedPercentOverMaxSupply < 0.5 && mintDetails.preminedPercentOverMaxSupply > 0
  const pendingMintPercentLessThanHalf =
    mintDetails.pendingMintPercentOverMaxSupply < 0.5 && mintDetails.pendingMintPercentOverMaxSupply > 0

  let mintedPercentToShow = mintDetails.mintedPercentOverMaxSupply
  let preminedPercentToShow = mintDetails.preminedPercentOverMaxSupply
  const pendingMintPercentToShow = mintDetails.isMintedOut ?
    0 : pendingMintPercentLessThanHalf ?
      0.5 : mintDetails.pendingMintPercentOverMaxSupply
  if (mintedPercentLessThanHalf && preminedPercentLessThanHalf) {
    mintedPercentToShow = 0.5
    preminedPercentToShow = 0.5
  } else if (mintedPercentLessThanHalf) {
    mintedPercentToShow = 0.5
    preminedPercentToShow =
      mintDetails.preminedPercentOverMaxSupply > 1
        ? mintDetails.preminedPercentOverMaxSupply - 0.5
        : mintDetails.preminedPercentOverMaxSupply
  } else if (preminedPercentLessThanHalf) {
    preminedPercentToShow = 0.5
    mintedPercentToShow =
      mintDetails.mintedPercentOverMaxSupply > 1
        ? mintDetails.mintedPercentOverMaxSupply - 0.5
        : mintDetails.mintedPercentOverMaxSupply
  }

  return (
    <>
      <Counts>
        <div></div>
        {mintDetails.isMintable && (
          <MintsRemainingCount>
            <Label>Mints Remaining:</Label>{' '}
            {formatNumber({
              numStr: mintDetails.mintsRemaining.toString(),
              useCompactNotation: isMobile,
            })}
          </MintsRemainingCount>
        )}
      </Counts>
      <ProgressBarWrapper
        percentages={[
          {
            key: 'premined',
            percentage: preminedPercentToShow,
            color: COLORS.white,
          },
          {
            key: 'minted',
            percentage: mintedPercentToShow,
            color: hexToRgb(COLORS.white, 0.4),
            showGradient: mintDetails.mintState === 'Minting',
          },
          {
            key: 'pending',
            percentage: pendingMintPercentToShow,
            color: hexToRgb(COLORS.white, 0.3),
          },
        ]}
      />
      <Counts>
        <PremineCount>
          <Label>Premined: </Label>
          {formatRunesAmount({ rune: runeDetails, runesAmount: runeDetails.amountPremined })}
        </PremineCount>
        {mintDetails.isMintable && (
          <MintCount>
            <Label>{mintDetails.mintEnded ? 'Final Mint Count' : 'Mint Count'}: </Label>
            {runeDetails.numberOfMints.toLocaleString()} /{' '}
            {formatNumber({
              numStr: (mintDetails.mintEnded
                ? runeDetails.numberOfMints
                : runeDetails.maxMints ?? 0n
              ).toString(),
              useCompactNotation: isMobile,
            })}{' '}
            ({mintDetails.formattedMintedPercent})
          </MintCount>
        )}
      </Counts>
    </>
  )
}

const ProgressBarWrapper = styled(MultiPartProgressBar)`
  height: 18px;
  border-radius: 100px;
  span {
    border-radius: 100px;
    background-color: ${COLORS.positive};
  }
`

const Counts = styled.div`
  display: flex;
  text-align: right;
  gap: 10px;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    text-align: left;
  }
`

const MintsRemainingCount = styled.span`
  float: right;
  display: flex;
  gap: 5px;
`

const MintCount = styled.span`
  color: ${COLORS.white};
  display: flex;
  gap: 5px;
`

const PremineCount = styled.span`
  color: ${COLORS.white};
  display: flex;
  gap: 5px;
`

const Label = styled.div`
  font-weight: 500;
`
