import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import Decimal from 'decimal.js-light'
import { useLocation, useSearchParams } from 'react-router-dom'

import {
  Market,
  Order,
  OrderInfo,
  Rune,
  RuneBalance,
  RuneDetails,
  RuneOutpointDetails,
} from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'
import { formatNumber, formatUsd, normalizeRuneName, priceSatsToPriceUsd } from '@packages/utils'

import { useApi, usePaginationApi } from 'src/api'
import { useMarket } from 'src/markets'
import { combineSearchParams, replaceUrlParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'
import { getCurrentRoute, ROUTES } from 'src/pages'

import { OrderSummary } from './interfaces'
import { BuyRunesDrawer, CancelOrderDrawer, SellRunesDrawer } from './components'
import { useAvailableBalanceMinusOrders } from './hooks'
import { calculatePrice, calculateWeightedAveragePrice } from './utils'

interface OrderContextType {
  availableRuneBalances: RuneBalance[]

  isLoadingNewRune: boolean

  selectedRuneName: string
  selectedRune?: Rune
  selectedRuneDetails?: RuneDetails
  detailsFetchError?: string
  selectedRuneMarket?: Market
  marketFetchError?: string
  setSelectedRuneName: (runeName: string) => void
  setSelectedRune: (rune: Rune) => void

  selectedRuneAvailableBalance: bigint

  setSelectedCancelOrder: (order: Order) => void

  isBuying: boolean
  setIsBuying: (isBuying: boolean) => void

  isStaleOrderDataTimestamp: number
  setIsStaleOrderDataTimestamp: React.Dispatch<React.SetStateAction<number>>
  recentlyFilledOrders: Order[]
  recentlyCancelledOrders: Order[]

  orderSummary?: OrderSummary
  selectedSellOrders: Order[]
  setSelectedSellOrders: React.Dispatch<React.SetStateAction<Order[]>>
  selectedRuneOutpoints: RuneOutpointDetails[]
  setSelectedRuneOutpoints: React.Dispatch<React.SetStateAction<RuneOutpointDetails[]>>

  desiredRunesSellAmount?: bigint
  desiredBtcSellAmount?: bigint
  setDesiredRunesSellAmount: (amount?: bigint) => void
  setDesiredBtcSellAmount: (amount?: bigint) => void

  openBuyDrawer: () => void
  isBuyDrawerOpen: boolean
  openSellDrawer: () => void
  isSellDrawerOpen: boolean
  openCancelDrawer: () => void
  isCancelDrawerOpen: boolean

  gaEventId: string
  setGaEventId: (gaEventId: string) => void
}

const OrderContext = createContext<OrderContextType | undefined>(undefined)

export const useOrderContext = () => {
  const context = useContext(OrderContext)
  if (context === undefined) {
    throw new Error('useOrderContext must be used within a OrderProvider')
  }
  return context
}

export const OrderProvider = ({ children }: { children: ReactNode }) => {
  const { runesAddress, btcPrice } = useWalletContext()
  const [previousRunesAddress, setPreviousRunesAddress] = useState<string | undefined>()

  const [isBuyRunesDrawerOpen, setIsBuyRunesDrawerOpen] = useState(false)
  const [isSellRunesDrawerOpen, setIsSellRunesDrawerOpen] = useState(false)
  const [isCancelOrderDrawerOpen, setIsCancelOrderDrawerOpen] = useState(false)

  const [selectedRuneName, setSelectedRuneName] = useState<string>('')
  const [rune, setRune] = useState<Rune>()

  const [isBuying, setIsBuying] = useState(false)
  const [orderSummary, setOrderSummary] = useState<OrderSummary>()

  const [selectedSellOrders, setSelectedSellOrders] = useState<Order[]>([])
  const [previouslySelectedSellOrders, setPreviouslySelectedSellOrders] = useState<Order[]>([])

  const [selectedRuneOutpoints, setSelectedRuneOutpoints] = useState<RuneOutpointDetails[]>([])

  const [isStaleOrderDataTimestamp, setIsStaleOrderDataTimestamp] = useState<number>(0)
  const [recentlyFilledOrders, setRecentlyFilledOrders] = useState<Order[]>([])
  const [recentlyCancelledOrders, setRecentlyCancelledOrders] = useState<Order[]>([])

  const [desiredRunesSellAmount, setDesiredRunesSellAmount] = useState<bigint>()
  const [desiredBtcSellAmount, setDesiredBtcSellAmount] = useState<bigint>()

  const [selectedCancelOrder, setSelectedCancelOrder] = useState<Order>()

  const [gaEventId, setGaEventId] = useState('')

  const { data: runeDetails, error: runeDetailsError } = useApi<RuneDetails>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.details, {
      runeName: selectedRuneName?.toUpperCase() ?? '',
    }),
    disable: !selectedRuneName,
  })

  const selectedRune = rune ?? runeDetails

  const { market, error: marketError } = useMarket(runeDetails?.runeId)

  const { availableBalance, forceRefresh: fetchAvailableBalance } = useAvailableBalanceMinusOrders({
    runeId: selectedRune?.runeId,
  })

  const { paginatedData: runeBalances, forceRefresh: fetchAvailableBalances } =
    usePaginationApi<RuneBalance>({
      endpoint: `${replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byAccount, {
        address: runesAddress?.addrString ?? '',
      })}?hideOrderBalances=true`,
      disabled: !runesAddress,
      limit: 200,
    })

  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const route = getCurrentRoute(location.pathname)
    if (route !== ROUTES.marketDetails) {
      reset()
    }
  }, [location])

  useEffect(() => {
    const side = searchParams.get('side')
    if (side === 'buy') {
      setIsBuying(true)
    } else if (side === 'sell') {
      setIsBuying(false)
    }
  }, [])

  useEffect(() => {
    const currentRunesAddress = runesAddress?.addrString
    if (currentRunesAddress !== previousRunesAddress) {
      setSelectedSellOrders((orders) =>
        orders.filter((order) => order.placedByAddress !== currentRunesAddress)
      )
      setPreviousRunesAddress(currentRunesAddress)
    }
  }, [runesAddress])

  useEffect(() => {
    let priceSats = '0'
    let totalSats = 0n
    let totalRunes = 0n
    if (selectedRune) {
      if (isBuying) {
        priceSats = calculateWeightedAveragePrice({ orders: selectedSellOrders, format: false })
        totalSats = selectedSellOrders.reduce((acc, item) => acc + item.satsAmount, 0n)
        totalRunes = selectedSellOrders.reduce((acc, item) => acc + item.runesAmount, 0n)
      } else {
        priceSats = calculatePrice({
          satsAmount: desiredBtcSellAmount ?? 0n,
          runesAmount: desiredRunesSellAmount ?? 0n,
          rune: selectedRune,
          format: false,
        })
        totalSats = desiredBtcSellAmount ?? 0n
        totalRunes = desiredRunesSellAmount ?? 0n
      }
    }

    let priceUsd = '$0.00'
    let totalUsd = '$0.00'

    if (btcPrice) {
      if (priceSats !== '' && !isNaN(priceSats as any)) {
        priceUsd = priceSatsToPriceUsd({
          satPriceUsd: btcPrice.satPriceUsd,
          priceSats,
          format: true,
        })
      }
      totalUsd = formatUsd({
        usd: new Decimal(`${totalSats}`).mul(btcPrice?.satPriceUsd).toNumber(),
      })
    }

    setOrderSummary({
      priceSats: formatNumber({ numStr: priceSats, showAllDecimals: true }),
      priceUsd,
      totalSats,
      totalUsd,
      totalRunes,
    })
  }, [
    selectedRune,
    selectedSellOrders,
    selectedRuneOutpoints,
    desiredBtcSellAmount,
    desiredRunesSellAmount,
    btcPrice,
    isBuying,
  ])

  // unselect or select more orders via the table
  useEffect(() => {
    if (selectedSellOrders.length === previouslySelectedSellOrders.length) {
      return
    }
    const runeChanged = previouslySelectedSellOrders[0]?.runeId !== selectedRune?.runeId

    const newOrderSatsAmount = selectedSellOrders.reduce((acc, item) => acc + item.satsAmount, 0n)
    const newOrderRunesAmount = selectedSellOrders.reduce((acc, item) => acc + item.runesAmount, 0n)

    if (newOrderRunesAmount !== desiredRunesSellAmount) {
      if (selectedSellOrders.length === 0 && runeChanged) {
        setDesiredRunesSellAmount(undefined)
      } else {
        setDesiredRunesSellAmount(newOrderRunesAmount)
      }
    }
    if (newOrderSatsAmount !== desiredBtcSellAmount) {
      if (selectedSellOrders.length === 0 && runeChanged) {
        setDesiredBtcSellAmount(undefined)
      } else {
        setDesiredBtcSellAmount(newOrderSatsAmount)
      }
    }

    if (selectedSellOrders.length !== 0) {
      onIsBuyingChange(true)
    }

    setPreviouslySelectedSellOrders(selectedSellOrders)
  }, [selectedSellOrders])

  useEffect(() => {
    if (!selectedRune) return

    setDesiredRunesSellAmount(0n)
  }, [selectedRune])

  function reset() {
    setSelectedRuneName('')
    setRune(undefined)

    setOrderSummary(undefined)
    setSelectedSellOrders([])
    setSelectedRuneOutpoints([])
    setIsStaleOrderDataTimestamp(0)
    setDesiredRunesSellAmount(undefined)
    setDesiredBtcSellAmount(undefined)
  }

  function fetchBalances() {
    fetchAvailableBalance()
    fetchAvailableBalances()
  }

  function onIsBuyingChange(_isBuying: boolean) {
    setSearchParams(combineSearchParams(searchParams, { side: _isBuying ? 'buy' : 'sell' }))
    setIsBuying(_isBuying)
  }

  function onSelectedRuneNameChange(runeName: string) {
    reset()
    setSelectedRuneName(runeName)
  }

  function onSelectedRuneChange(_rune: Rune) {
    reset()
    setRune(_rune)
    setSelectedRuneName(_rune.runeName)
  }

  async function closeSellDrawer({ clearSelectedOutpoints }: { clearSelectedOutpoints?: boolean }) {
    if (clearSelectedOutpoints) {
      setSelectedRuneOutpoints([])
      setDesiredBtcSellAmount(undefined)
      setDesiredRunesSellAmount(undefined)
      setIsStaleOrderDataTimestamp(Date.now())
    }
    setIsSellRunesDrawerOpen(false)
    setTimeout(() => fetchBalances(), 1000 * 5)
  }

  async function closeBuyDrawer({
    filledOrders,
    validOrders,
  }: {
    filledOrders?: Order[]
    validOrders?: OrderInfo[]
  }) {
    if (filledOrders?.length && filledOrders.length > 0) {
      setRecentlyFilledOrders((prev) => [...filledOrders, ...prev])
      setSelectedSellOrders([])
      setDesiredBtcSellAmount(undefined)
      setDesiredRunesSellAmount(undefined)
      setIsStaleOrderDataTimestamp(Date.now())
    }
    if (validOrders) {
      setSelectedSellOrders(
        selectedSellOrders.filter((order) => validOrders.find((o) => o.orderId === order.id))
      )
      setIsStaleOrderDataTimestamp(Date.now())
    }
    setIsBuyRunesDrawerOpen(false)
  }

  async function closeCancelDrawer({ cancelledOrder }: { cancelledOrder?: Order }) {
    setSelectedCancelOrder(undefined)
    setIsCancelOrderDrawerOpen(false)
    if (cancelledOrder) {
      setRecentlyCancelledOrders((prev) => (cancelledOrder ? [cancelledOrder, ...prev] : prev))
    }

    await fetchBalances()
  }

  const balance = runeBalances.flat().find((r) => r.runeId === selectedRune?.runeId)

  const isLoadingNewRune =
    !market ||
    !runeDetails ||
    normalizeRuneName(selectedRuneName) !== market.runeName ||
    normalizeRuneName(selectedRuneName) !== runeDetails.runeName

  return (
    <OrderContext.Provider
      value={{
        availableRuneBalances: runeBalances.flat(),
        isLoadingNewRune,

        setSelectedRuneName: onSelectedRuneNameChange,
        setSelectedRune: onSelectedRuneChange,
        selectedRuneName,
        selectedRune: isLoadingNewRune ? undefined : selectedRune,
        selectedRuneDetails: isLoadingNewRune ? undefined : runeDetails,
        detailsFetchError: runeDetailsError,
        selectedRuneMarket: isLoadingNewRune ? undefined : market,
        marketFetchError: marketError,

        selectedRuneAvailableBalance:
          isLoadingNewRune && balance?.runeId !== selectedRune?.runeId
            ? 0n
            : balance?.runesAmount ?? availableBalance,

        setSelectedCancelOrder,

        isBuying,
        setIsBuying: onIsBuyingChange,

        orderSummary,
        selectedSellOrders,
        setSelectedSellOrders,
        selectedRuneOutpoints,
        setSelectedRuneOutpoints,

        isStaleOrderDataTimestamp,
        setIsStaleOrderDataTimestamp,
        recentlyFilledOrders,
        recentlyCancelledOrders,

        desiredRunesSellAmount,
        desiredBtcSellAmount,
        setDesiredRunesSellAmount,
        setDesiredBtcSellAmount,

        openBuyDrawer: () => setIsBuyRunesDrawerOpen(true),
        isBuyDrawerOpen: isBuyRunesDrawerOpen,
        openSellDrawer: () => setIsSellRunesDrawerOpen(true),
        isSellDrawerOpen: isSellRunesDrawerOpen,
        openCancelDrawer: () => {
          setIsCancelOrderDrawerOpen(true)
        },
        isCancelDrawerOpen: isCancelOrderDrawerOpen,

        gaEventId,
        setGaEventId,
      }}
    >
      {children}
      {selectedRune && (
        <>
          <BuyRunesDrawer
            isOpen={isBuyRunesDrawerOpen}
            onClose={closeBuyDrawer}
            orders={selectedSellOrders}
            rune={selectedRune}
            gaEventId={gaEventId}
            setGaEventId={setGaEventId}
          />
          <SellRunesDrawer
            isOpen={isSellRunesDrawerOpen}
            onClose={closeSellDrawer}
            outpoints={selectedRuneOutpoints}
            rune={selectedRune}
            runesAmount={desiredRunesSellAmount}
            satsAmount={desiredBtcSellAmount}
            gaEventId={gaEventId}
            setGaEventId={setGaEventId}
          />
        </>
      )}
      {selectedCancelOrder && (
        <CancelOrderDrawer
          isOpen={isCancelOrderDrawerOpen}
          onClose={closeCancelDrawer}
          order={selectedCancelOrder}
        />
      )}
    </OrderContext.Provider>
  )
}
