import { v4 as uuidv4 } from 'uuid'

import { ENVIRONMENT } from 'src/settings'

function _blockAnalytics(window: Window): boolean {
  // Don't send GA events when running locally
  if (ENVIRONMENT == 'local') return true
  if (typeof window.gtag !== 'function') return true

  return false
}
/* Wallet workflow. 

*/

export function GA_ConnectWallet(window: Window, provider: string) {
  if (_blockAnalytics(window)) return

  window.gtag('event', 'connect_wallet', {
    event_category: 'user',
    provider: provider,
  })

  console.log('sending GA event - connect_wallet')
}

// The user clicks on a Bitcoin address (either on the rune overview page or )
export function GA_ViewAccount(window: Window) {
  if (_blockAnalytics(window)) return

  window.gtag('event', 'view_account', {
    event_category: 'user',
  })

  console.log('sending GA event - view_account')
}

/* Minting workflow. 
When a user clicks mint on a certain rune it will initiate an event chain. The first event in the chain is 
the InitiateMint event which will return an event_id. This event_id must be used in the following events
within the same chain.
*/

// The user clicks the mint button on a rune overview page
export function GA_InitiateMint(window: Window, rune_name: string): string {
  if (_blockAnalytics(window)) return ''

  const event_id = uuidv4()
  window.gtag('event', 'initiate_mint', {
    event_category: 'mint',
    event_id: event_id,
    rune_name: rune_name,
  })

  console.log('sending GA event - initiate_mint - ', event_id)

  return event_id
}

// The user clicks the confirm mint button on the marketplace and is prompted for a signature
export function GA_ConfirmMint(
  window: Window,
  event_id: string,
  rune_name: string,
  mint_amount: number,
  fee: number
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'confirm_mint', {
    event_category: 'mint',
    event_id: event_id,
    rune_name: rune_name,
    mint_amount: mint_amount,
    fee: fee,
  })

  console.log('sending GA event - confirm_mint - ', event_id)

  return event_id
}

// The user successfully signs the minting transaction
export function GA_ExecuteMint(
  window: Window,
  event_id: string,
  rune_name: string,
  mint_amount: number,
  fee: number
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'execute_mint', {
    event_category: 'mint',
    event_id: event_id,
    rune_name: rune_name,
    mint_amount: mint_amount,
    fee: fee,
  })

  console.log('sending GA event - execute_mint - ', event_id)

  return event_id
}

// The user abadons the minting screen at any point of the journey. If there is no input for mint_amount or fee, it will
// assume the user quit before data entry
export function GA_AbandonMint(
  window: Window,
  event_id: string,
  rune_name: string,
  mint_amount: number = 0,
  fee: number = 0
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'abandon_mint', {
    event_category: 'mint',
    event_id: event_id,
    rune_name: rune_name,
    mint_amount: mint_amount,
    fee: fee,
  })

  console.log('sending GA event - abandon_mint - ', event_id)

  return event_id
}

/* Orders workflow. 
When a user clicks swap on a certain rune it will initiate an event chain. The first event in the chain is 
the InitiateSwap event which will return an event_id. This event_id must be used in the following events
within the same chain.
*/

// The user clicks to fill orders from the orderbook
export function GA_AddOrder(
  window: Window,
  event_id: string,
  rune_name: string,
  order_amount: bigint,
  price: bigint
): string {
  if (_blockAnalytics(window)) return ''

  if (event_id == '') {
    event_id = uuidv4()
  }
  window.gtag('event', 'add_order', {
    event_category: 'order',
    event_id: event_id,
    rune: rune_name,
    amount: order_amount,
    action: 'buy',
    price: price,
  })

  console.log('sending GA event - add_order - ', event_id)

  return event_id
}

// The user clicks to unfill orders from the orderbook
export function GA_RemoveOrder(
  window: Window,
  event_id: string,
  rune_name: string,
  order_amount: bigint,
  price: bigint
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'remove_order', {
    event_category: 'order',
    event_id: event_id,
    rune: rune_name,
    amount: order_amount,
    action: 'buy',
    price: price,
  })

  console.log('sending GA event - remove_order - ', event_id)

  return event_id
}

// The user clicks the swap or place order button. If 'swap', the action should be buy (as the user is attempting to buy runes)
// if 'place order', the action should be sell (as the user is attempting to sell runes)
type OrderAction = 'buy' | 'sell'
export function GA_InitiateSwap(
  window: Window,
  event_id: string = '',
  rune_name: string,
  order_amount: bigint,
  order_action: OrderAction,
  price: bigint
): string {
  if (_blockAnalytics(window)) return ''

  if (event_id == '') {
    event_id = uuidv4()
  }

  window.gtag('event', 'initiate_swap', {
    event_category: 'order',
    event_id: event_id,
    rune: rune_name,
    amount: order_amount,
    action: order_action,
    price: price,
  })

  console.log('sending GA event - initiate_swap - ', event_id)

  return event_id
}

// The user clicks the confirm swap button on the marketplace and is prompted for a signature
export function GA_ConfirmSwap(
  window: Window,
  event_id: string,
  rune_name: string,
  order_amount: bigint,
  order_action: OrderAction,
  fee: number,
  price: bigint
) {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'confirm_swap', {
    event_category: 'order',
    event_id: event_id,
    rune_name: rune_name,
    amount: order_amount,
    action: order_action,
    price: price,
    fee: fee,
  })

  console.log('sending GA event - confirm_swap - ', event_id)

  return event_id
}

// The user successfully signs the order transaction
export function GA_ExecuteSwap(
  window: Window,
  event_id: string,
  rune_name: string,
  order_amount: bigint,
  order_action: OrderAction,
  fee: number,
  price: bigint
) {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'execute_swap', {
    event_category: 'order',
    event_id: event_id,
    rune_name: rune_name,
    amount: order_amount,
    action: order_action,
    price: price,
    fee: fee,
  })

  console.log('sending GA event - execute_swap - ', event_id)

  return event_id
}

// The user abadons the order screen at any point of the journey. If there is no input for fee, it will
// assume the user quit before data entry
export function GA_AbandonSwap(
  window: Window,
  event_id: string,
  rune_name: string,
  order_amount: bigint,
  order_action: OrderAction,
  fee: number = 0,
  price: bigint
) {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'abandon_swap', {
    event_category: 'order',
    event_id: event_id,
    rune_name: rune_name,
    amount: order_amount,
    action: order_action,
    price: price,
    fee: fee,
  })

  console.log('sending GA event - abandon_swap - ', event_id)

  return event_id
}

// The user cancels the sell order they submitted through the marketplace
export function GA_CancelOrder(
  window: Window,
  event_id: string,
  rune_name: string,
  order_amount: bigint,
  price: bigint
) {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'cancel_order', {
    event_category: 'order',
    event_id: event_id,
    rune_name: rune_name,
    amount: order_amount,
    action: 'sell',
    price: price,
  })

  console.log('sending GA event - cancel_order - ', event_id)

  return event_id
}

/* Transfers workflow. 

*/

// The user clicks the transfer button on a rune overview page
export function GA_InitiateTransfer(window: Window, rune_name: string): string {
  if (_blockAnalytics(window)) return ''

  const event_id = uuidv4()
  window.gtag('event', 'initiate_transfer', {
    event_category: 'transfer',
    event_id: event_id,
    rune_name: rune_name,
  })

  console.log('sending GA event - initiate_transfer - ', event_id)

  return event_id
}

// The user clicks the confirm transfer button on the marketplace and is prompted for a signature
export function GA_ConfirmTransfer(
  window: Window,
  event_id: string,
  rune_name: string,
  transfer_amount: bigint,
  fee: number
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'confirm_transfer', {
    event_category: 'transfer',
    event_id: event_id,
    rune_name: rune_name,
    transfer_amount: transfer_amount,
    fee: fee,
  })

  console.log('sending GA event - confirm_transfer - ', event_id)

  return event_id
}

// The user successfully signs the transfering transaction
export function GA_ExecuteTransfer(
  window: Window,
  event_id: string,
  rune_name: string,
  transfer_amount: bigint,
  fee: number
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'execute_transfer', {
    event_category: 'transfer',
    event_id: event_id,
    rune_name: rune_name,
    transfer_amount: transfer_amount,
    fee: fee,
  })

  console.log('sending GA event - execute_transfer - ', event_id)

  return event_id
}

// The user abandons the tranfer flow
export function GA_AbandonTransfer(
  window: Window,
  event_id: string,
  rune_name: string,
  transfer_amount: bigint,
  fee: number
): string {
  if (_blockAnalytics(window)) return ''

  window.gtag('event', 'abandon_transfer', {
    event_category: 'transfer',
    event_id: event_id,
    rune_name: rune_name,
    transfer_amount: transfer_amount,
    fee: fee,
  })

  console.log('sending GA event - abandon_transfer - ', event_id)

  return event_id
}
