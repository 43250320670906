import { styled } from 'styled-components'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { Order } from '@packages/interfaces'
import { formatUsd, formatRuneName } from '@packages/utils'

import {
  Divider,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTitle,
  ErrorMessage,
  SuccessToast,
  TieredTableCell,
} from 'src/shared/components'
import { useWalletContext } from 'src/wallet'
import { RunesAmountDisplay } from 'src/runes'
import { unixTimestampToDateString } from 'src/shared/utils'
import { GA_CancelOrder } from 'src/shared/utils/analytics'

import { PriceSatsDisplay } from './PriceSatsDisplay'
import { BtcAmountDisplay } from './BtcAmountDisplay'

interface Props {
  isOpen: boolean
  onClose: ({ cancelledOrder }: { cancelledOrder?: Order }) => void

  order: Order
}

export function CancelOrderDrawer({ isOpen, onClose, order }: Props) {
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()

  const { cancelSellRunes } = useWalletContext()

  function resetForm() {
    setError(undefined)
    setLoadingText(undefined)
  }

  async function onConfirmClick() {
    try {
      setLoadingText('Cancelling')
      setError(undefined)

      const response = await cancelSellRunes({ order })

      if (response.success) {
        console.log('Order cancelled', order)

        toast(<SuccessToast message='Order cancelled' />, {
          toastId: 'order-cancelled-' + order.id,
        })
        GA_CancelOrder(window, '', order.runeName, order.runesAmount, order.satsAmount)
        resetForm()
        onClose({ cancelledOrder: order })
      } else {
        setError(response.error)
      }
    } catch (error: any) {
      console.error(error)
      setError(error.message ?? 'Error cancelling order')
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    setError(undefined)
    onClose({})
  }

  const buttonText = loadingText ?? 'Confirm Cancel'

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose}>
      <DrawerCard>
        <DrawerTitle>Cancel Sell Order</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Rune</DrawerBoldColumn>
          <DrawerColumn>{formatRuneName(order)}</DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>Placed At</DrawerBoldColumn>
          <DrawerColumn>{unixTimestampToDateString(order.placedAtTimestamp, true)}</DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>Price</DrawerBoldColumn>
          <DrawerColumn>
            <TieredTableCell
              header={
                <PriceSatsDisplay priceSats={order.priceSats} runeSymbol={order.runeSymbolChar} />
              }
              subHeader={order.priceUsd}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>Quantity</DrawerBoldColumn>
          <QuantityColum>
            <RunesAmountDisplay rune={order} runesAmount={order.runesAmount} />
          </QuantityColum>
        </DrawerRow>
        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>Total BTC</DrawerBoldColumn>
          <DrawerColumn>
            <TieredTableCell
              header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
              subHeader={formatUsd({ usd: order.valueUsd })}
            />
          </DrawerColumn>
        </DrawerRow>
      </DrawerCard>

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => setLoadingText(undefined)}>
            Cancel Tx
          </DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton loading={!!loadingText} onClick={onConfirmClick}>
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const QuantityColum = styled(DrawerColumn)`
  font-weight: 700;
`
