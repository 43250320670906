import { base64ToHex, decodeBase64, safeJSONParse } from 'src/shared/utils'

export function decodeSignMessageRequest(request: string): string {
  /**
     * request: <encoding_info>.<request_info_see_below>
     * {
        "network":{
            "type":"Testnet",
            "address":"tb1pgvk5845m7mds46ndfnf2yfev93ex26s0was7c6qmep0jrk9ct7ssy9tmcg"
        },
        "address":"tb1pgvk5845m7mds46ndfnf2yfev93ex26s0was7c6qmep0jrk9ct7ssy9tmcg",
        "message":"Hello World. Welcome to Mystic!"
    }
     */
  if (request.includes('.')) {
    const req = request.split('.')
    const { message } = safeJSONParse(decodeBase64(req[1]))
    return message
  }

  throw new Error('Invalid sign message request')
}

export function decodeSignTransactionRequest(request: string): {
  psbtHex: string
  psbtBase64: string
  broadcast: boolean
  inputsToSign: { address: string; signingIndexes: number[]; sigHash: number }[]
} {
  /**
     * request: <encoding_info>.<request_info_see_below>
     * {
      "network":{
          "type":"Testnet"
      },
      "message":"Sign Transaction",
      "psbtBase64":"cHNidP8BAP0SAQIAAAAC7ygJQxgTAmwU+W9/9DX/TMWFe2I1+59YHXdPVTwEzVwCAAAAAP/////vKAlDGBMCbBT5b3/0Nf9MxYV7YjX7n1gdd09VPATNXAIAAAAA/////wSCCgAAAAAAACJRIEMtQ9ab9tsK6m1M0qInLCxyZWoPd2HsaBvIXyHYuF+hrgsAAAAAAAAiUSBDLUPWm/bbCuptTNKiJywscmVqD3dh7GgbyF8h2LhfoQAAAAAAAAAAIlEgQy1D1pv22wrqbUzSoicsLHJlag93YexoG8hfIdi4X6EAAAAAAAAAACypdilTUDFLU045R1oyMUY0QjNEWkQ0VFE5SlpYS0ZUWkUzV1c1R1hSRVFLWAAAAAAAAQEgrgsAAAAAAAAXqRSdrsfviXxOeHAVrtbaIfvIl+xHiocBAwSDAAAAAQQWABSxb/ytVf9o6Ny4Gj9hRjB738lwwwABASuuCwAAAAAAACJRIEMtQ9ab9tsK6m1M0qInLCxyZWoPd2HsaBvIXyHYuF+hAQMEgwAAAAEXIB894l9d2GEk2C9xZjhVBOBmOgS7fweXRFkVXIHmnyx2AAAAAAA=",
      "broadcast":false,
      "inputsToSign":[
          {
            "address":"tb1pgvk5845m7mds46ndfnf2yfev93ex26s0was7c6qmep0jrk9ct7ssy9tmcg",
            "signingIndexes":[
                0
            ],
            "sigHash":131
          },
          {
            "address":"tb1pgvk5845m7mds46ndfnf2yfev93ex26s0was7c6qmep0jrk9ct7ssy9tmcg",
            "signingIndexes":[
                1
            ],
            "sigHash":131
          }
      ]
    }
     */

  if (request.includes('.')) {
    const req = request.split('.')
    const { psbtBase64, broadcast, inputsToSign } = safeJSONParse(decodeBase64(req[1]))
    return { psbtHex: base64ToHex(psbtBase64), psbtBase64, broadcast, inputsToSign }
  }

  throw new Error('Invalid sign transaction request')
}

export function decodeSendBitcoinRequest(request: string): { address: string; amount: number } {
  /**
     * request: <encoding_info>.<request_info_see_below>
     * {
        "network":{
            "type":"Testnet"
        },
        "senderAddress":"tb1q0nsa5e9l0pedexzqup6v90qewf5su5t6pxtpze",
        "recipients":[
            {
              "address":"tb1pgvk5845m7mds46ndfnf2yfev93ex26s0was7c6qmep0jrk9ct7ssy9tmcg",
              "amountSats":"66"
            }
        ]
      }
     */
  if (request.includes('.')) {
    const req = request.split('.')
    const { recipients } = safeJSONParse(decodeBase64(req[1]))
    if (recipients.length !== 1) {
      throw new Error('Invalid send bitcoin request. Only one recipient allowed.')
    }

    const recipient = recipients[0]
    return {
      address: recipient.address,
      amount: parseInt(recipient.amountSats),
    }
  }

  throw new Error('Invalid send bitcoin request')
}
