import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import {
  AddressPurpose,
  BitcoinNetworkType,
  getAddress,
  getCapabilities,
  sendBtcTransaction,
  signMessage,
  signMultipleTransactions,
  signTransaction,
} from 'sats-connect'
import { base64 } from '@scure/base'

import * as btc from '@packages/scure-btc-signer'
import * as psbt from '@packages/scure-btc-signer/psbt'
import * as wasm from '@packages/rune-wasm'
import { set_wasm } from '@packages/rune-wasm/set_wasm'
import {
  Block,
  BtcPrice,
  BuyRequest,
  BuyRequestResult,
  BuySubmission,
  BuySubmissionResult,
  CancelSubmission,
  GenericChallenge,
  MintsSubmission,
  MintsSubmissionResult,
  Order,
  RecommendedFees,
  Rune,
  RuneOutpoint,
  RuneOutpointDetails,
  SellSubmission,
  Settings,
} from '@packages/interfaces'
import { P2TROut } from '@packages/scure-btc-signer/payment'
import { API_ENDPOINTS, SATOSHI_DUST_THRESHOLD } from '@packages/constants'
import { calculateFeeFromBps } from '@packages/utils'

import { useInterval, useLocalStorage } from 'src/shared/hooks'
import {
  DEPLOYED_BITCOIN_NETWORK,
  IS_TESTNET_DEPLOYMENT,
  TRX_VIRTUAL_SIZE_BYTES,
} from 'src/settings'
import { apiFetch } from 'src/api'
import { hexToBase64, replaceUrlParams } from 'src/shared/utils'
import { GA_ConnectWallet } from 'src/shared/utils/analytics'

import {
  useUnisatProvider,
  useAddressLocalStorage,
  useLeatherProvider,
  useOkxProvider,
  useMagicEdenProvider,
} from './hooks'
import {
  BuildBulkMintResult,
  BuildBulkMintSerializedResult,
  buildBulkMintTxs,
  buildEtchingCommit,
  buildEtchingReveal,
  buildMintTx,
  buildSellPsbt,
  createRuneTransferTx,
  EtchingCommit,
  finalizeTx,
  getAddressBtcBalances,
  getAddressDetails,
  getBlockTip,
  getBtcPrice,
  getOutpointId,
  getRecommendedNetworkFees,
  getRuneTransactionInputs,
  JsEtchingParams,
  SignBulkMintResult,
  SignBulkMintSerializedResult,
  uint8ArrayEquals,
  getCleanUtxos,
  finalizeAndBroadcastTx,
  estimatedMintParentVsize,
  estimatedMintChildVsize,
  getXverseProvider,
} from './utils'
import { isCustomBitcoinProvider } from './providers'
import {
  AddressDetails,
  BtcBalances,
  BtcSignerNetwork,
  BtcSignerOutput,
  RuneUtxoOutpoint,
} from './interfaces'
import { WALLET_NAME, WalletName } from './constants'
import BulkMintWebWorker from './utils/bulkMintWebWorker?worker&inline'
import { IncorrectAccountPopup } from './components'

import type {
  Address,
  BitcoinProvider,
  Capability,
  Recipient,
  SignMultipleTransactionsPayload,
  SignTransactionPayload,
} from 'sats-connect'

const BLOCK_INTERVAL_MS = 1000 * 60 * 1
const NETWORK_FEES_INTERVAL_MS = 1000 * 15
const BTC_PRICE_INTERVAL_MS = 1000 * 60 * 3

interface WalletContextType {
  settings: Settings
  btcBalances?: BtcBalances
  btcPrice?: BtcPrice
  blockTip?: Block
  recommendedNetworkFees?: RecommendedFees

  paymentAddress?: AddressDetails
  ordinalsAddress?: AddressDetails
  runesAddress?: AddressDetails
  walletName?: WalletName

  network: BitcoinNetworkType
  isConnected: boolean
  capabilities?: Set<Capability>
  capabilityState: 'loading' | 'loaded' | 'missing' | 'cancelled'
  isReady: boolean
  toggleNetwork: () => void
  connectWallet: (provider: BitcoinProvider, walletName: WalletName) => Promise<void>
  disconnectWallet: () => Promise<void>

  signMessage: (message: string, addressPurpose: AddressPurpose) => Promise<SignMessageResult>
  signTransaction: (payload: SignTransactionPayload) => Promise<SignTransactionResult>
  signMultipleTransactions: (
    payload: SignMultipleTransactionsPayload
  ) => Promise<SignMultipleTransactionsResult>
  sendBitcoin: (recipients: Recipient[]) => Promise<SendBitcoinResult>

  commitEtchRune: (payload: {
    etchParams: JsEtchingParams
    fee: bigint
  }) => Promise<CommitEtchRuneResult>

  revealEtchRune: (payload: {
    etchParams: JsEtchingParams
    etchingCommit: EtchingCommit
    fee: bigint
  }) => Promise<SignTransactionResult>

  mintRune: (payload: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
  }) => Promise<SignTransactionResult>

  mintBulkRune: (payload: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
    quantity: bigint
    onStatusChange(status: BulkMintSignTransactionStatus): void
  }) => Promise<SignMultipleTransactionsResult>

  estimateMintBulkRuneVsize: (payload: {
    quantity: bigint
    addFee: boolean
  }) => Promise<{ child: number; parent: number }>

  getRunesOutpointsForSale: (
    params: GetRuneOutpointsForSaleParams
  ) => Promise<RuneOutpointDetails[]>

  sellRunes: (payload: SellRunesParams) => Promise<SignTransactionResult>

  cancelSellRunes: (payload: CancelSellRunesParams) => Promise<SignMessageResult>

  requestBuyRunes: (params: RequestBuyRunesParams) => Promise<BuyRequestResult>

  submitBuyRunes: (
    params: SubmitBuyRunesParams
  ) => Promise<SignTransactionResult | BuyRequestResult>

  estimateBuyRunesVsize: (params: SubmitBuyRunesParams) => Promise<number>

  transferRunes: (payload: {
    recipientAddress: string
    rune: Rune
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: TransferRunesTransactionStatus): void
  }) => Promise<SignTransactionResult>

  estimateTransferRunesVsize: (payload: {
    rune: Rune
    transferAmount: bigint
    fee: bigint
  }) => Promise<number>
}

export interface SellRunesParams {
  proceeds: bigint
  proceedsAddress: string
  rune: Rune
  sellAmount: bigint
  runeOutpoints: RuneOutpointDetails[]
  fee: bigint
  onStatusChange(status: SellRunesTransactionStatus): void
}

interface CancelSellRunesParams {
  order: Order
}

interface GetRuneOutpointsForSaleParams {
  runeId: string
  sellAmount: bigint
}

interface RequestBuyRunesParams {
  rune: Rune
  orders: Order[]
}

interface SubmitBuyRunesParams extends RequestBuyRunesParams {
  fee: bigint
  onStatusChange(status: BuyRunesTransactionStatus): void
}

type BulkMintSignTransactionStatus = 'build' | 'wallet-prompt' | 'temp-key-sign' | 'api-submit'

type SellRunesTransactionStatus =
  | 'build'
  | 'transfer-wallet-prompt'
  | 'sell-wallet-prompt-not-boxed'
  | 'sell-wallet-prompt-boxed'
  | 'api-submit'

type BuyRunesTransactionStatus = 'build' | 'wallet-prompt' | 'api-submit'

type TransferRunesTransactionStatus = 'build' | 'wallet-prompt'

interface WalletActionResult {
  success: boolean
  error?: string
}

interface WalletActionErrorResult extends WalletActionResult {
  success: false
  error: string
}

interface SignMessageSuccessResult extends WalletActionResult {
  success: true
  signedMessage: string
}

type SignMessageResult = SignMessageSuccessResult | WalletActionErrorResult

interface SignTransactionSuccessResult extends WalletActionResult {
  success: true
  signedPsbtBase64: string
  txId?: string
}

export type SignTransactionResult = SignTransactionSuccessResult | WalletActionErrorResult

interface SignMultipleTransactionsSuccessResult extends WalletActionResult {
  success: true
  signedTransactionResults: (SignTransactionSuccessResult | WalletActionErrorResult)[]
}

type SignMultipleTransactionsResult =
  | SignMultipleTransactionsSuccessResult
  | WalletActionErrorResult

interface SendBitcoinSuccessResult extends WalletActionResult {
  success: true
  txId: string
}

type SendBitcoinResult = SendBitcoinSuccessResult | WalletActionErrorResult

const WalletContext = createContext<WalletContextType | undefined>(undefined)

export const useWalletContext = () => {
  const context = useContext(WalletContext)
  if (context === undefined) {
    throw new Error('useWalletContext must be used within a WalletProvider')
  }
  return context
}

export interface CommitEtchRuneResult {
  commitTxResult: SignTransactionResult
  revealAmount: bigint
  txId: string
  revealScript: P2TROut
  revealKey: Uint8Array
  commitTx: btc.Transaction
}

function formatUtxo({ txId, vout, value }: { txId: string; vout: number; value: number }) {
  return {
    value,
    status: {
      confirmed: false,
      block_height: 0,
      block_hash: '',
      block_time: 0,
    },
    txid: txId,
    vout,
  }
}

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [wasmInitialized, setWasmInitialized] = useState(false)
  const [walletName, setWalletName] = useLocalStorage<WalletName | undefined>('walletName')

  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    'network',
    DEPLOYED_BITCOIN_NETWORK
  )
  const [btcSignerNetwork, setBtcSignerNetwork] = useLocalStorage<BtcSignerNetwork>(
    'btcSignerNetwork',
    DEPLOYED_BITCOIN_NETWORK === BitcoinNetworkType.Testnet ? btc.TEST_NETWORK : btc.NETWORK
  )

  const [paymentAddress, setPaymentAddress] = useAddressLocalStorage(
    'paymentAddress',
    walletName ?? ''
  )
  const [ordinalsAddress, setOrdinalsAddress] = useAddressLocalStorage(
    'ordinalsAddress',
    walletName ?? ''
  )
  const [runesAddress, setRunesAddress] = useAddressLocalStorage('runesAddress', walletName ?? '')

  const [capabilityState, setCapabilityState] = useState<
    'loading' | 'loaded' | 'missing' | 'cancelled'
  >('loading')
  const [capabilities, setCapabilities] = useState<Set<Capability>>()
  const [provider, setProvider] = useState<BitcoinProvider | undefined>()

  const [btcBalances, setBtcBalances] = useLocalStorage<BtcBalances | undefined>('btcBalances')
  const [blockTip, setBlockTip] = useLocalStorage<Block | undefined>('blockTip')
  const [recommendedNetworkFees, setRecommendedNetworkFees] = useLocalStorage<
    RecommendedFees | undefined
  >('recommendedNetworkFees')
  const [btcPrice, setBtcPrice] = useLocalStorage<BtcPrice | undefined>('btcPrice')

  const [settings, setSettings] = useState<Settings>({
    serviceFees: {
      receiverAddress: '',
      feeBps: {
        mint: 0,
        order: 0,
      },
    },
  })

  const [showIncorrectAccountPopup, setShowIncorrectAccountPopup] = useState(false)

  useEffect(() => {
    if (!wasmInitialized) {
      set_wasm()
      wasm.init()
      setWasmInitialized(true)
    }

    apiFetch<Settings>(API_ENDPOINTS.GET.settings.get).then((response) => {
      setSettings(response)
    })
  }, [])

  useEffect(() => {
    const runCapabilityCheck = async () => {
      let runs = 0
      const MAX_RUNS = 20
      setCapabilityState('loading')

      try {
        await getCapabilities({
          onFinish(response) {
            setCapabilities(new Set(response))
            setCapabilityState('loaded')
          },
          onCancel() {
            setCapabilityState('cancelled')
          },
          payload: {
            network: {
              type: network,
            },
          },
        })
      } catch (e) {
        runs++
        if (runs === MAX_RUNS) {
          setCapabilityState('missing')
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 100))
    }

    runCapabilityCheck()

    setBtcSignerNetwork(IS_TESTNET_DEPLOYMENT ? btc.TEST_NETWORK : btc.NETWORK)
  }, [network])

  // btc balance

  useEffect(() => {
    if (paymentAddress) {
      getAddressBtcBalances({
        setBtcBalances,
        address: paymentAddress.addrString,
        forceUpdate: btcBalances === undefined,
      })
    }
  }, [paymentAddress?.addrString, blockTip])

  // chain info

  useEffect(() => {
    getBlockTip({ setBlockTip, currentBlockTip: blockTip })
    getRecommendedNetworkFees({ setRecommendedNetworkFees })
    getBtcPrice({ setBtcPrice })
  }, [])

  useInterval(async () => {
    await getBlockTip({ setBlockTip, currentBlockTip: blockTip })
  }, BLOCK_INTERVAL_MS)

  useInterval(async () => {
    await getRecommendedNetworkFees({ setRecommendedNetworkFees })
  }, NETWORK_FEES_INTERVAL_MS)

  useInterval(async () => {
    await getBtcPrice({ setBtcPrice })
  }, BTC_PRICE_INTERVAL_MS)

  // reconnect to wallet provider on refresh

  const magicEdenProvider = useMagicEdenProvider()
  useEffect(() => {
    if (magicEdenProvider && !provider && walletName === WALLET_NAME.magicEden) {
      setProvider(magicEdenProvider)
    }
  }, [magicEdenProvider, provider, walletName])

  const unisatProvider = useUnisatProvider()
  useEffect(() => {
    if (unisatProvider && !provider && walletName === WALLET_NAME.unisat) {
      setProvider(unisatProvider)
    }
  }, [unisatProvider, provider, walletName])

  const leatherProvider = useLeatherProvider()
  useEffect(() => {
    if (leatherProvider && !provider && walletName === WALLET_NAME.leather) {
      setProvider(leatherProvider)
    }
  }, [leatherProvider, provider, walletName])

  const okxProvider = useOkxProvider()
  useEffect(() => {
    if (okxProvider && !provider && walletName === WALLET_NAME.okx) {
      setProvider(okxProvider)
    }
  }, [okxProvider, provider, walletName])

  const xverseProvider = getXverseProvider()
  useEffect(() => {
    if (walletName && !provider) {
      switch (walletName) {
        case WALLET_NAME.xverse:
        case WALLET_NAME.default:
          if (xverseProvider) {
            setProvider(xverseProvider)
          }
          break
      }
    }
  }, [walletName, provider, xverseProvider])

  const isReady =
    paymentAddress !== undefined &&
    ordinalsAddress !== undefined &&
    runesAddress !== undefined &&
    !!walletName
  const isConnected = !!provider && isReady

  const disconnectWallet = async () => {
    if (isCustomBitcoinProvider(provider)) {
      await provider.disconnect()
    }

    setPaymentAddress(undefined)
    setOrdinalsAddress(undefined)
    setRunesAddress(undefined)
    setProvider(undefined)
    setWalletName(undefined)
    setBtcBalances(undefined)
  }

  const toggleNetwork = () => {
    setNetwork(network)
    disconnectWallet()
  }

  const connectWallet = async (_provider: BitcoinProvider, walletName: WalletName) => {
    GA_ConnectWallet(window, walletName)

    function addressItemToAddressDetails(addressItem: Address | undefined) {
      return getAddressDetails({ walletName, addressItem })
    }

    await getAddress({
      getProvider: async () => _provider,
      payload: {
        purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
        message: 'Welcome to Mystic - a Rune marketplace.',
        network: {
          type: network,
        },
      },
      onFinish: (response) => {
        const paymentAddressItem = response.addresses.find(
          (address) => address.purpose === AddressPurpose.Payment
        )
        const _paymentAddress = addressItemToAddressDetails(paymentAddressItem)
        setPaymentAddress(_paymentAddress)

        const ordinalsAddressItem = response.addresses.find(
          (address) => address.purpose === AddressPurpose.Ordinals
        )
        const _ordinalsAddress = addressItemToAddressDetails(ordinalsAddressItem)
        setOrdinalsAddress(_ordinalsAddress)
        // set rune address to ordinals address for now
        setRunesAddress(_ordinalsAddress)
        setProvider(_provider)
        setWalletName(walletName)
      },
      onCancel: () => alert('Request canceled'),
    })
  }

  const _signMessage = async (
    message: string,
    addressPurpose: AddressPurpose
  ): Promise<SignMessageResult> => {
    return new Promise((resolve) => {
      const address = addressPurpose === AddressPurpose.Payment ? paymentAddress : ordinalsAddress
      if (!address) {
        resolve({
          success: false,
          error: 'Not connected, address not available',
        })
        return
      }

      signMessage({
        getProvider: async () => provider,
        payload: {
          network: {
            type: network,
          },
          address: address.addrString,
          message,
        },
        onFinish: (signedMessage) => {
          resolve({ success: true, signedMessage })
        },
        onCancel: () => {
          resolve({ success: false, error: 'Signing message canceled' })
        },
      }).catch((error) => {
        resolve({ success: false, error: error.message })
      })
    })
  }

  const _signTransaction = async (
    payload: SignTransactionPayload
  ): Promise<SignTransactionResult> => {
    if (walletName === WALLET_NAME.unisat && isCustomBitcoinProvider(provider)) {
      const res = await provider.getCurrentAddress()
      if (
        !payload.inputsToSign.find((input) =>
          res.addresses.find((address) => address.address === input.address)
        )
      ) {
        setShowIncorrectAccountPopup(true)
        throw new Error('Incorrect active account')
      }
    }

    return new Promise((resolve) => {
      signTransaction({
        getProvider: async () => provider,
        payload,
        onFinish: (response) => {
          resolve({
            success: true,
            signedPsbtBase64: response.psbtBase64,
            txId: response.txId,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((error) => {
        resolve({
          success: false,
          error: error.message,
        })
      })
    })
  }

  const _mintRune = async ({
    runeId,
    runeName,
    recipientAddress,
    fee,
  }: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    const tx = await buildMintTx({
      paymentAddress: paymentAddress,
      recipientAddress,
      runeId,
      network: btcSignerNetwork,
      fee,
    })

    if (!tx) {
      throw new Error('Failed to create transaction')
    }

    const indicesToSign: number[] = []
    for (let i = 0; i < tx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to mint ${runeName} Runes`,
      psbtBase64: base64.encode(tx.toPSBT()),
    })

    if (!result.success) {
      throw new Error('Failed to mint Runes')
    }

    if (!result.txId && walletName === WALLET_NAME.magicEden) {
      result.txId = await finalizeAndBroadcastTx(result.signedPsbtBase64)
    }

    return result
  }

  const _estimateMintBulkRuneVsize = async ({
    quantity,
    addFee,
  }: {
    quantity: bigint
    addFee: boolean
  }): Promise<{ parent: number; child: number }> => {
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    return {
      parent: estimatedMintParentVsize(Number(quantity), addFee),
      child: estimatedMintChildVsize(),
    }
  }

  const _mintBulkRune = async ({
    runeId,
    runeName,
    recipientAddress,
    fee,
    quantity,
    onStatusChange,
  }: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
    quantity: bigint
    onStatusChange(status: BulkMintSignTransactionStatus): void
  }): Promise<SignMultipleTransactionsResult> => {
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    onStatusChange('build')

    // need to call estimate twice to get the correct vsize of the parent and child transactions
    const estimate = await buildBulkMintTxs({
      paymentAddress: paymentAddress,
      recipientAddress,
      serviceFeeReceiverAddress: settings.serviceFees.receiverAddress,
      serviceFeeMintBps: settings.serviceFees.feeBps.mint,
      runeId,
      network: btcSignerNetwork,
      estimatedTotalNetworkFee: 0n,
      childEstimatedVsize: BigInt(TRX_VIRTUAL_SIZE_BYTES.mint),
      fee,
      quantity,
    })
    const parentEstimatedVsize = estimate.parentTx.estimatedVsize
    const childEstimatedVsize = estimate.childTxs[0].estimatedP2TRVsize
    const estimatedTotalNetworkFee =
      fee * BigInt(parentEstimatedVsize) + fee * BigInt(childEstimatedVsize) * quantity

    const bulk = await buildBulkMintTxs({
      paymentAddress: paymentAddress,
      recipientAddress,
      serviceFeeReceiverAddress: settings.serviceFees.receiverAddress,
      serviceFeeMintBps: settings.serviceFees.feeBps.mint,
      runeId,
      network: btcSignerNetwork,
      fee,
      estimatedTotalNetworkFee,
      childEstimatedVsize: BigInt(childEstimatedVsize),
      quantity,
    })

    const indicesToSign: number[] = []
    for (let i = 0; i < bulk.parentTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    onStatusChange('wallet-prompt')
    const result = await _signTransaction({
      broadcast: false,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to prepare bulk mint of ${runeName}`,
      psbtBase64: base64.encode(bulk.parentTx.toPSBT()),
    })

    if (!result.success) {
      console.warn('Failed to mint runes', result.error)
      return result
    }

    onStatusChange('temp-key-sign')
    const worker = new BulkMintWebWorker()

    function signBulkMintTxsInWorker(bulk: BuildBulkMintResult): Promise<SignBulkMintResult> {
      return new Promise((resolve, reject) => {
        worker.onmessage = (event: MessageEvent) => {
          const serializedResult: SignBulkMintSerializedResult = event.data
          const response: SignBulkMintResult = {
            ...serializedResult,
            parentTx: btc.Transaction.fromPSBT(base64.decode(serializedResult.parentTxBase64)),
            childTxs: serializedResult.childTxsBase64.map((tx) =>
              btc.Transaction.fromPSBT(base64.decode(tx))
            ),
          }
          resolve(response)
        }
        worker.onerror = (error: ErrorEvent) => {
          console.error('Worker error:', error)
          reject(new Error('Error preparing mints'))
        }

        const bulkSerialized: BuildBulkMintSerializedResult = {
          ...bulk,
          parentTxSignedBase64: (result as SignTransactionSuccessResult).signedPsbtBase64,
          childTxsBase64: bulk.childTxs.map((tx) => base64.encode(tx.toPSBT())),
        }
        worker.postMessage({
          action: 'signBulkMintTxs',
          bulk: bulkSerialized,
        })
      })
    }

    const signed = await signBulkMintTxsInWorker(bulk)

    const splitterPsbtBase64 = base64.encode(signed.parentTx.toPSBT())
    const mintsSubmission: MintsSubmission = {
      splitterSignerAddress: paymentAddress.addrString,
      minterSignerAddress: bulk.tempKeyAddress,
      splitterPsbtBase64,
      mintPsbtsBase64: signed.childTxs.map((tx) => base64.encode(tx.toPSBT())),
      runeId,
      quantity,
    }

    onStatusChange('api-submit')
    const response = await apiFetch<MintsSubmissionResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.mints.submitMints, { runeName }),
      undefined,
      {
        body: mintsSubmission,
        method: 'POST',
      }
    )

    if (!!response.error || !response.splitterTxId) {
      console.error('Failed to mint Runes', response.error ?? 'No error', response.splitterTxId)
      return {
        success: false,
        error: response.error ?? 'Failed to mint Runes',
      }
    }

    const results: SignTransactionResult[] = [
      {
        txId: response.splitterTxId,
        success: true,
        signedPsbtBase64: splitterPsbtBase64,
      },
    ]

    for (const txId of response.mintTxIds ?? []) {
      results.push({
        success: true,
        txId,
        signedPsbtBase64: '',
      })
    }
    for (const error of response.mintErrors ?? []) {
      results.push({
        success: false,
        error: error[1],
      })
    }

    return { success: true, signedTransactionResults: results }
  }

  const _transferRunes = async ({
    recipientAddress,
    rune,
    transferAmount,
    fee,
    onStatusChange,
  }: {
    recipientAddress: string
    rune: Rune
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: TransferRunesTransactionStatus): void
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Runes or Payment address not available')
    }

    onStatusChange('build')
    const { tx: runeTransferTx, inputsToSign } = await createRuneTransferTx({
      runesAddress,
      paymentAddress,
      recipientAddress,
      runeId: rune.runeId,
      transferAmount,
      network: btcSignerNetwork,
      fee,
    })

    if (!runeTransferTx) {
      throw new Error('Failed to create transaction')
    }

    onStatusChange('wallet-prompt')
    const result = await _signTransaction({
      broadcast: true,
      network: { type: network },
      message: `Transfer ${transferAmount.toLocaleString()} ${
        rune.runeSymbolChar ?? rune.runeName
      }`,
      psbtBase64: base64.encode(runeTransferTx.toPSBT()),
      inputsToSign,
    })

    if (!result.success) {
      throw new Error(result.error)
    }

    if (!result.txId && walletName === WALLET_NAME.magicEden) {
      result.txId = await finalizeAndBroadcastTx(result.signedPsbtBase64)
    }

    return result
  }

  const _estimateTransferRunesVsize = async ({
    rune,
    transferAmount,
    fee,
  }: {
    rune: Rune
    transferAmount: bigint
    fee: bigint
  }) => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Runes or Payment address not available')
    }

    const { tx: runeTransferTx } = await createRuneTransferTx({
      runesAddress,
      paymentAddress,
      recipientAddress: paymentAddress.addrString, // not needed for estimate
      runeId: rune.runeId,
      transferAmount,
      network: btcSignerNetwork,
      fee,
    })

    return runeTransferTx.estimatedP2TRVsize
  }

  const _getRunesOutpointsForSale = async ({
    runeId,
    sellAmount,
  }: GetRuneOutpointsForSaleParams): Promise<RuneOutpointDetails[]> => {
    if (!runesAddress) {
      throw new Error('Rune address not available')
    }

    const outpoints: RuneUtxoOutpoint[] = await getRuneTransactionInputs(
      runesAddress,
      runeId,
      sellAmount
    )

    return outpoints.map((outpoint) => ({
      runeId,
      outpointId: getOutpointId(outpoint),
      amount: outpoint.amount,
      value: BigInt(outpoint.utxo.value),
      txId: outpoint.utxo.txid,
      vout: BigInt(outpoint.utxo.vout),
    }))
  }

  const _sellRunes = async ({
    proceedsAddress,
    proceeds,
    rune,
    sellAmount,
    fee,
    runeOutpoints = [],
    onStatusChange,
  }: SellRunesParams): Promise<SignTransactionResult> => {
    if (!runesAddress) {
      throw new Error('Rune address not available')
    }
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    onStatusChange('build')

    const formattedOutpoints: RuneUtxoOutpoint[] = await Promise.all(
      runeOutpoints.map(async (outpoint) => {
        const runeUtxo: RuneUtxoOutpoint = {
          runeId: rune.runeId,
          amount: outpoint.amount,
          utxo: formatUtxo({
            txId: outpoint.txId,
            vout: Number(outpoint.vout),
            value: Number(outpoint.value),
          }),
        }

        return runeUtxo
      })
    )
    const outpointsTotal = formattedOutpoints.reduce(
      (total, outpoint) => total + outpoint.amount,
      0n
    )

    // box transaction
    let boxedOutpoint: RuneUtxoOutpoint
    let boxTransactionResult: SignTransactionSuccessResult | undefined
    let spendingOutpointIds: string[] = []

    let mustBox = false

    // if formattedOutpoints.length === 1, check if there are more than one rune type on that outpoint. if so, mustBox = true
    if (formattedOutpoints.length === 1) {
      const outpointId = `${formattedOutpoints[0].utxo.txid}:${formattedOutpoints[0].utxo.vout}`
      const runeOutpoints = await apiFetch<RuneOutpoint[]>(
        `${replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.runeAmountsForOutpoint, {
          outpointId,
        })}`
      )
      if (runeOutpoints.length > 1) {
        mustBox = true
      }
      if (formattedOutpoints[0].utxo.value > 10000) {
        mustBox = true
      }
    }

    // if splitting or combining outpoints use transfer tx
    if (outpointsTotal !== sellAmount || formattedOutpoints.length > 1 || mustBox) {
      const {
        tx: runeTransferTx,
        inputsToSign,
        spendingUtxos,
      } = await createRuneTransferTx({
        runesAddress,
        paymentAddress,
        recipientAddress: runesAddress.addrString,
        runeId: rune.runeId,
        transferAmount: sellAmount,
        network: btcSignerNetwork,
        runeUtxoOutpoints: formattedOutpoints,
        fee,
      })

      if (!runeTransferTx) {
        throw new Error('Failed to create transaction')
      }

      onStatusChange('transfer-wallet-prompt')
      const boxResult = await _signTransaction({
        broadcast: false,
        network: { type: network },
        message: 'Prepare Runes for sale',
        psbtBase64: base64.encode(runeTransferTx.toPSBT()),
        inputsToSign,
      })

      if (!boxResult.success) {
        throw new Error('Failed to prepare Runes')
      }

      onStatusChange('build')

      const finalizedBoxTx = finalizeTx(boxResult.signedPsbtBase64)

      spendingOutpointIds = spendingUtxos.map((outpoint) => getOutpointId(outpoint))

      boxTransactionResult = {
        success: true,
        txId: finalizedBoxTx.id,
        signedPsbtBase64: hexToBase64(finalizedBoxTx.hex),
      }

      boxedOutpoint = {
        runeId: rune.runeId,
        amount: sellAmount,
        utxo: formatUtxo({
          txId: finalizedBoxTx.id,
          vout: 0,
          value: Number(SATOSHI_DUST_THRESHOLD),
        }),
      }
    } else {
      // preboxed outpoint
      boxedOutpoint = formattedOutpoints[0]
    }

    const sellTx = await buildSellPsbt({
      proceedsAddress,
      proceedsAmount: proceeds,
      runeAddressDetails: runesAddress,
      runeId: rune.runeId,
      runeUtxo: boxedOutpoint,
      network: btcSignerNetwork,
    })

    if (!sellTx) {
      throw new Error('Failed to create transaction')
    }

    if (boxTransactionResult) {
      onStatusChange('sell-wallet-prompt-boxed')
    } else {
      onStatusChange('sell-wallet-prompt-not-boxed')
    }

    const sellResult = await _signTransaction({
      broadcast: false,
      inputsToSign: [
        {
          address: runesAddress.addrString,
          signingIndexes: [0],
          sigHash: btc.SigHash.SINGLE_ANYONECANPAY,
        },
      ],
      network: {
        type: network,
      },
      message: 'Sign to sell Runes',
      psbtBase64: base64.encode(sellTx.toPSBT()),
    })

    if (!sellResult.success) {
      throw new Error('Failed to create sell tx')
    }

    const sellSubmission: SellSubmission = {
      runeId: rune.runeId,
      amountRunes: sellAmount,
      amountSats: proceeds,
      signedByAddress: runesAddress.addrString,
      sellPsbt: {
        dataBase64: sellResult.signedPsbtBase64,
        assertions: {
          boxedOutpointId: getOutpointId(boxedOutpoint),
          btcProceedsAddress: proceedsAddress,
        },
      },
    }

    if (boxTransactionResult) {
      sellSubmission.boxTransaction = {
        dataBase64: boxTransactionResult.signedPsbtBase64,
        assertions: {
          transactionId: boxTransactionResult.txId!,
          changeAddress: runesAddress.addrString,
          spendingOutpointIds: spendingOutpointIds,
        },
      }
    }

    onStatusChange('api-submit')
    await apiFetch(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.sell, {
        runeName: rune.runeName,
      }),
      {},
      { method: 'POST', body: sellSubmission }
    )

    return {
      success: true,
      txId: sellResult.txId!,
      signedPsbtBase64: sellResult.signedPsbtBase64,
    }
  }

  const _cancelSellRunes = async ({ order }: CancelSellRunesParams): Promise<SignMessageResult> => {
    if (!runesAddress) {
      throw new Error('Runes address not available')
    }
    const { challenge } = await apiFetch<GenericChallenge>(
      `${API_ENDPOINTS.GET.auth.challenge}?message="Sign this message to cancel your order"`
    )
    const signatureResponse = await _signMessage(challenge, AddressPurpose.Ordinals)

    if (!signatureResponse.success) {
      return signatureResponse
    }

    const cancelRequest: CancelSubmission = {
      runeId: order.runeId,
      orderId: order.id,
      signature: signatureResponse.signedMessage,
      address: runesAddress.addrString,
      challenge,
    }
    const cancelSuccessful = await apiFetch<boolean>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.cancel, {
        runeName: order.runeName,
        orderId: order.id.toString(),
      }),
      {},
      {
        body: cancelRequest,
        method: 'POST',
      }
    )

    if (!cancelSuccessful) {
      return {
        success: false,
        error: 'Failed to cancel order',
      }
    }

    return {
      success: true,
      signedMessage: '',
    }
  }

  const _requestBuyRunes = async ({
    rune,
    orders,
  }: RequestBuyRunesParams): Promise<BuyRequestResult> => {
    const buyRequest: BuyRequest = {
      runeId: rune.runeId,
      orderIds: orders.map((order) => order.id),
    }

    const buyRequestResponse = await apiFetch<BuyRequestResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.requestBuy, {
        runeName: rune.runeName,
        orderId: orders[0].id.toString(),
      }),
      {},
      {
        body: buyRequest,
        method: 'POST',
      }
    )

    return buyRequestResponse
  }

  async function buildBuyOrderPsbt({
    sellPsbtBase64,
    fee,
  }: {
    fee: bigint
    sellPsbtBase64: string
  }): Promise<{ buyTx: btc.Transaction; indicesToSign: number[] }> {
    if (!runesAddress) {
      throw new Error('Rune address not available')
    }
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    const orderPsbt = btc.Transaction.fromPSBT(base64.decode(sellPsbtBase64))
    let orderCost = 0n
    const orderInputs: psbt.TransactionInputUpdate[] = Array.from(
      Array(orderPsbt.inputsLength).keys()
    ).map((i) => orderPsbt.getInput(i))

    const orderOutputs: BtcSignerOutput[] = Array.from(Array(orderPsbt.outputsLength).keys()).map(
      (i) => {
        const output = orderPsbt.getOutput(i)
        if (!output || !output.amount || !output.script) throw new Error('Bad order psbt output')

        orderCost += output.amount

        return {
          amount: output.amount,
          script: output.script,
        }
      }
    )

    const paymentUtxos = await getCleanUtxos(paymentAddress)

    const runeReceiverValue = 546n
    const runeReceiverAddress = runesAddress.addrString

    const serviceFeeValue = calculateFeeFromBps(orderCost, settings.serviceFees.feeBps.order)
    const serviceFeeReceiverAddress = settings.serviceFees.receiverAddress

    const buyOutputs = [
      { amount: runeReceiverValue, address: runeReceiverAddress },
      ...orderOutputs,
      ...(serviceFeeValue !== 0n
        ? [{ amount: serviceFeeValue, address: serviceFeeReceiverAddress }]
        : []),
    ]

    const selected = btc.selectUTXO(paymentUtxos, buyOutputs, 'default', {
      requiredInputs: orderInputs,
      feePerByte: fee,
      changeAddress: paymentAddress.addrString,
      bip69: false, // Do not sort outputs
      network: btcSignerNetwork,
      createTx: false,
    })

    if (!selected) {
      throw new Error('Failed to find sufficient UTXO')
    }

    const paymentInputCount = selected.inputs.length - orderInputs.length
    if (paymentInputCount < 1) {
      throw new Error('Buy order tx construction failed; missing inputs')
    }

    const paymentInputs = selected.inputs.slice(0, paymentInputCount)

    const buyTx = new btc.Transaction()

    // add first payment input & buyer output to receive runes
    buyTx.addInput(paymentInputs[0])
    buyTx.addOutputAddress(runeReceiverAddress, runeReceiverValue, btcSignerNetwork)

    // merge all sale psbt input & output
    for (let i = 0; i < orderPsbt.inputsLength; i++) {
      buyTx.addInput(orderPsbt.getInput(i))
    }
    for (let i = 0; i < orderPsbt.outputsLength; i++) {
      buyTx.addOutput(orderPsbt.getOutput(i))
    }

    // add service fee output if non-zero
    if (serviceFeeValue > 0n) {
      buyTx.addOutputAddress(serviceFeeReceiverAddress, serviceFeeValue, btcSignerNetwork)
    }

    // add remaining payment utxo
    const indicesToSign = [0]
    for (let i = 1; i < paymentInputCount; i++) {
      indicesToSign.push(buyTx.addInput(paymentInputs[i]))
    }

    if (selected.change) {
      buyTx.addOutputAddress(
        paymentAddress.addrString,
        selected.outputs[selected.outputs.length - 1].amount,
        btcSignerNetwork
      )
    }

    return { buyTx, indicesToSign }
  }

  const _estimateBuyRunesVsize = async ({
    rune,
    orders,
    fee,
  }: SubmitBuyRunesParams): Promise<number> => {
    if (!runesAddress) {
      throw new Error('Rune address not available')
    }
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    const buyRequestResponse = await _requestBuyRunes({ rune, orders })

    if (buyRequestResponse.validOrders.length === 0) {
      throw new Error('None of your selected orders are available')
    }

    if (buyRequestResponse.validOrders.length < orders.length) {
      return 0
    }

    if (!buyRequestResponse.psbt) {
      return 0
    }

    const { buyTx } = await buildBuyOrderPsbt({
      sellPsbtBase64: buyRequestResponse.psbt.dataBase64,
      fee,
    })

    return buyTx.estimatedVsize
  }

  const _submitBuyRunes = async ({
    rune,
    orders,
    fee,
    onStatusChange,
  }: SubmitBuyRunesParams): Promise<SignTransactionResult | BuyRequestResult> => {
    if (!runesAddress) {
      throw new Error('Rune address not available')
    }
    if (!paymentAddress) {
      throw new Error('Payment address not available')
    }

    onStatusChange('build')

    const buyRequestResponse = await _requestBuyRunes({ rune, orders })

    if (buyRequestResponse.validOrders.length === 0) {
      throw new Error('None of your selected orders are available')
    }

    if (buyRequestResponse.validOrders.length < orders.length) {
      return buyRequestResponse
    }

    if (!buyRequestResponse.psbt) {
      throw new Error('Failed to create buy request')
    }

    const { buyTx, indicesToSign } = await buildBuyOrderPsbt({
      sellPsbtBase64: buyRequestResponse.psbt.dataBase64,
      fee,
    })

    // we are not including a runestone
    const firstOutput = buyTx.getOutput(0)
    if (!firstOutput.script || !uint8ArrayEquals(firstOutput.script, runesAddress.p2.script)) {
      throw new Error('Rune receiver output script mismatch')
    }

    onStatusChange('wallet-prompt')

    const result = await _signTransaction({
      broadcast: false,
      inputsToSign: [
        {
          address: paymentAddress.addrString,
          signingIndexes: indicesToSign,
          sigHash: btc.SigHash.ALL,
        },
      ],
      network: {
        type: network,
      },
      message: 'Sign to buy Runes',
      psbtBase64: base64.encode(buyTx.toPSBT()),
    })

    if (!result.success) {
      throw new Error(result.error)
    }

    const buySubmission: BuySubmission = {
      runeId: rune.runeId,
      orders: buyRequestResponse.validOrders,
      buyerAddress: runesAddress.addrString,
      psbt: {
        dataBase64: result.signedPsbtBase64,
      },
    }

    onStatusChange('api-submit')

    const buySubmissionResponse = await apiFetch<BuySubmissionResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.submitBuy, {
        runeName: rune.runeName,
        orderId: buyRequestResponse.validOrders[0].orderId.toString(),
      }),
      {},
      {
        body: buySubmission,
        method: 'POST',
      }
    )

    if (buySubmissionResponse.allOrdersFilled) {
      return { ...result, txId: buySubmissionResponse.txId }
    } else if (buySubmissionResponse.retry) {
      return buySubmissionResponse.retry
    } else if (buySubmissionResponse.error) {
      return { ...result, success: false, error: buySubmissionResponse.error }
    }

    return { ...result, success: false, error: 'Failed to submit buy order' }
  }

  const _commitEtchRune = async ({
    etchParams,
    fee,
  }: {
    etchParams: JsEtchingParams
    fee: bigint
  }): Promise<CommitEtchRuneResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Payment or Rune address not available')
    }

    const etchingCommit = await buildEtchingCommit({
      paymentAddress: paymentAddress,
      recipientAddress: runesAddress,
      network: btcSignerNetwork,
      fee,
      params: etchParams,
    })

    if (!etchingCommit.commitTx) {
      throw new Error('Failed to create commit transaction')
    }

    const indicesToSign: number[] = []
    for (let i = 0; i < etchingCommit.commitTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to commit ${etchParams.rune} Etching`,
      psbtBase64: base64.encode(etchingCommit.commitTx.toPSBT()),
    })

    if (!result.success) {
      throw new Error(`Failed to commit ${etchParams.rune} Etching`)
    }

    console.log('psbt', result.signedPsbtBase64)
    console.log('txid', result.txId)

    const tx = btc.Transaction.fromPSBT(base64.decode(result.signedPsbtBase64))

    return {
      commitTxResult: result,
      revealAmount: etchingCommit.revealAmount,
      txId: result.txId!,
      revealScript: etchingCommit.revealScript,
      revealKey: etchingCommit.revealKey,
      commitTx: tx,
    }
  }

  const _revealEtchRune = async ({
    etchParams,
    etchingCommit,
    fee,
  }: {
    etchParams: JsEtchingParams
    etchingCommit: EtchingCommit
    fee: bigint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new Error('Payment or Rune address not available')
    }

    const etchingReveal = await buildEtchingReveal({
      paymentAddress: paymentAddress,
      recipientAddress: runesAddress,
      network: btcSignerNetwork,
      fee,
      params: etchParams,
      etchingCommit: etchingCommit,
    })

    if (!etchingReveal.revealTx) {
      throw new Error('Failed to create reveal transaction')
    }

    const indicesToSign: number[] = []
    for (let i = 0; i < etchingReveal.revealTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: runesAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to reveal ${etchParams.rune} Etching`,
      psbtBase64: base64.encode(etchingReveal.revealTx.toPSBT()),
    })

    if (!result.success) {
      throw new Error(`Failed to reveal ${etchParams.rune} Etching`)
    }

    console.log('psbt', result.signedPsbtBase64)
    console.log('txid', result.txId)

    return result
  }

  const _signMultipleTransactions = async (
    payload: SignMultipleTransactionsPayload
  ): Promise<SignMultipleTransactionsResult> => {
    return new Promise((resolve) => {
      signMultipleTransactions({
        getProvider: async () => provider,
        payload,
        onFinish: (response) => {
          resolve({
            success: true,
            signedTransactionResults: response.map((result) => ({
              success: true,
              signedPsbtBase64: result.psbtBase64,
              txId: result.txId,
            })),
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((e) => {
        resolve({
          success: false,
          error: e.message,
        })
      })
    })
  }

  const sendBitcoin = async (recipients: Recipient[]): Promise<SendBitcoinResult> => {
    return new Promise((resolve) => {
      sendBtcTransaction({
        getProvider: async () => provider,
        payload: {
          network: {
            type: network,
          },
          recipients,
          senderAddress: paymentAddress!.addrString,
        },
        onFinish: (response) => {
          resolve({
            success: true,
            txId: response,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((e) => {
        resolve({
          success: false,
          error: e.message,
        })
      })
    })
  }

  return (
    <WalletContext.Provider
      value={{
        settings,
        btcBalances,
        btcPrice,
        recommendedNetworkFees,
        blockTip,
        paymentAddress,
        ordinalsAddress,
        runesAddress,
        walletName: walletName,
        isConnected,
        network,
        capabilities,
        capabilityState,
        isReady,
        toggleNetwork,
        connectWallet,
        disconnectWallet,
        signMessage: _signMessage,
        signTransaction: _signTransaction,
        signMultipleTransactions: _signMultipleTransactions,
        sendBitcoin,
        commitEtchRune: _commitEtchRune,
        revealEtchRune: _revealEtchRune,
        mintRune: _mintRune,
        mintBulkRune: _mintBulkRune,
        estimateMintBulkRuneVsize: _estimateMintBulkRuneVsize,
        transferRunes: _transferRunes,
        estimateTransferRunesVsize: _estimateTransferRunesVsize,
        getRunesOutpointsForSale: _getRunesOutpointsForSale,
        sellRunes: _sellRunes,
        cancelSellRunes: _cancelSellRunes,
        requestBuyRunes: _requestBuyRunes,
        submitBuyRunes: _submitBuyRunes,
        estimateBuyRunesVsize: _estimateBuyRunesVsize,
      }}
    >
      <IncorrectAccountPopup
        isOpen={showIncorrectAccountPopup}
        onClose={() => setShowIncorrectAccountPopup(false)}
      />
      {children}
    </WalletContext.Provider>
  )
}
