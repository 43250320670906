import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import Decimal from 'decimal.js-light'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { toast } from 'react-toastify'

import { BTC_PRICE_SYMBOL, Order, OrderInfo, Rune } from '@packages/interfaces'
import {
  formatBtc,
  formatUsd,
  satsToBtc,
  formatRuneName,
  formatNumber,
  formatUsdPrice,
  calculateFeeFromBps,
} from '@packages/utils'

import {
  AccordianHeader,
  Accordion,
  AccordionContent,
  Divider,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTieredContent,
  DrawerTitle,
  ErrorMessage,
  TieredTableCell,
  VirtualizedTable,
  WarningMessage,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { useWalletContext } from 'src/wallet'
import { RuneSymbolDisplay, RunesAmountDisplay, formatRunesAmount } from 'src/runes'
import { useBtcBalance } from 'src/wallet/hooks'
import { DISABLE_WRITES, TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'
import { useDebounce, useIsMobile } from 'src/shared/hooks'
import { hexToRgb } from 'src/shared/utils'
import {
  GA_AbandonSwap,
  GA_ConfirmSwap,
  GA_ExecuteSwap,
  GA_InitiateSwap,
} from 'src/shared/utils/analytics'

import { calculateWeightedAveragePrice, formatPriceSymbol } from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'

interface Props {
  isOpen: boolean
  onClose: ({
    filledOrders,
    validOrders,
  }: {
    filledOrders?: Order[]
    validOrders?: OrderInfo[]
  }) => void
  rune: Rune
  orders: Order[]
  gaEventId: string
  setGaEventId: (gaEventId: string) => void
}

export function BuyRunesDrawer({ isOpen, onClose, orders, rune, gaEventId, setGaEventId }: Props) {
  const isMobile = useIsMobile()
  const [animateRefParent] = useAutoAnimate()

  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()
  const [validOrders, setValidOrders] = useState<OrderInfo[]>([])
  const [isOrderDetailsExpanded, setIsOrderDetailsExpanded] = useState(false)
  const [runesAmountTotal, setRunesAmountTotal] = useState<bigint>(0n)
  const [isTotalsLoading, setIsTotalsLoading] = useState(false)
  const [ordersTotals, setOrdersTotals] = useState<{
    ordersSatsTotal: bigint
    ordersBtcTotal: number
    ordersUsdTotal: number
    btcTotal: number
    usdTotal: number
    marketplaceFeeAmountBtcTotal: number
    marketplaceFeeAmountUsdTotal: number
    networkFeeBtcTotal: number
    networkFeeUsdTotal: number
  }>({
    ordersSatsTotal: 0n,
    ordersBtcTotal: 0,
    ordersUsdTotal: 0,
    btcTotal: 0,
    usdTotal: 0,
    marketplaceFeeAmountBtcTotal: 0,
    marketplaceFeeAmountUsdTotal: 0,
    networkFeeBtcTotal: 0,
    networkFeeUsdTotal: 0,
  })

  const { settings, btcPrice, requestBuyRunes, submitBuyRunes } = useWalletContext()
  const { btcBalance } = useBtcBalance()

  useDebounce(
    async () => {
      if (orders.length === 0 || !rune) {
        return
      }
      try {
        const buyResult = await requestBuyRunes({
          rune,
          orders,
        })
        setValidOrders(buyResult.validOrders)
      } catch (error) {
        console.error(error)
      }
    },
    [orders, rune],
    500
  )

  useEffect(() => {
    const total = orders.reduce((acc, curr) => {
      return acc + curr.runesAmount
    }, 0n)

    setRunesAmountTotal(total)
  }, [orders])

  useDebounce(
    async () => {
      if (orders.length === 0 || !btcPrice || !rune || !selectedNetworkFee) {
        return
      }
      setIsTotalsLoading(true)
      try {
        const btcPriceUsd = btcPrice?.btcPriceUsd ?? 0
        const satPriceUsd = btcPrice?.satPriceUsd ?? 0
        const totals = orders
          .map((order) => {
            return {
              satsTotal: order.satsAmount,
              runesAmountTotal: order.runesAmount,
            }
          })
          .reduce(
            (acc, curr) => {
              return {
                satsTotal: acc.satsTotal + curr.satsTotal,

                runesAmountTotal: acc.runesAmountTotal + curr.runesAmountTotal,
              }
            },
            {
              satsTotal: 0n,
              runesAmountTotal: 0n,
            }
          )
        const networkFeeSatsTotal =
          selectedNetworkFee *
          (TRX_VIRTUAL_SIZE_BYTES.buy.each * orders.length +
            TRX_VIRTUAL_SIZE_BYTES.buy.initial +
            TRX_VIRTUAL_SIZE_BYTES.sell)
        const ordersBtcTotal = satsToBtc(totals.satsTotal)
        const serviceFeeSatsTotal = calculateFeeFromBps(
          totals.satsTotal,
          settings.serviceFees.feeBps.order
        )
        const btcTotal = satsToBtc(
          totals.satsTotal + BigInt(networkFeeSatsTotal) + BigInt(serviceFeeSatsTotal)
        )

        setOrdersTotals({
          ordersSatsTotal: totals.satsTotal,
          ordersBtcTotal,
          ordersUsdTotal: new Decimal(ordersBtcTotal).mul(btcPriceUsd).toNumber(),
          btcTotal,
          usdTotal: new Decimal(btcTotal).mul(btcPriceUsd).toNumber(),
          marketplaceFeeAmountBtcTotal: satsToBtc(serviceFeeSatsTotal),
          marketplaceFeeAmountUsdTotal: new Decimal(`${serviceFeeSatsTotal}`)
            .mul(satPriceUsd)
            .toNumber(),
          networkFeeBtcTotal: satsToBtc(networkFeeSatsTotal),
          networkFeeUsdTotal: new Decimal(networkFeeSatsTotal).mul(satPriceUsd).toNumber(),
        })
      } catch (error: any) {
        console.error(error)
      } finally {
        setIsTotalsLoading(false)
      }
    },
    [selectedNetworkFee, btcPrice, orders],
    250
  )

  useEffect(() => {
    if (isOpen) {
      const eventId = GA_InitiateSwap(
        window,
        gaEventId,
        rune.runeName,
        runesAmountTotal,
        'buy',
        ordersTotals.ordersSatsTotal
      )
      if (gaEventId == '') setGaEventId(eventId)
    }
  }, [isOpen])

  const filteredOrders = orders.filter((order) =>
    validOrders.some((validOrder) => validOrder.orderId === order.id)
  )

  function resetForm() {
    setGaEventId('')
    setError(undefined)
    setSelectedNetworkFee(0)
  }

  async function onConfirmClick() {
    try {
      setLoadingText('Generating tx')
      setError(undefined)
      GA_ConfirmSwap(
        window,
        gaEventId,
        rune.runeName,
        runesAmountTotal,
        'buy',
        selectedNetworkFee,
        ordersTotals.ordersSatsTotal
      )

      const ordersToBuy = [...filteredOrders]

      const buyResult = await submitBuyRunes({
        rune,
        orders: ordersToBuy,
        fee: BigInt(selectedNetworkFee),
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'wallet-prompt':
              setLoadingText('See wallet')
              break
            case 'api-submit':
              setLoadingText('Submitting order')
              break
          }
        },
      })

      if ('runeId' in buyResult) {
        setValidOrders(buyResult.validOrders)
        setError('Some of your selected orders are no longer available. See updated orders above.')
      } else {
        if (buyResult.success) {
          GA_ExecuteSwap(
            window,
            gaEventId,
            rune.runeName,
            runesAmountTotal,
            'buy',
            selectedNetworkFee,
            ordersTotals.ordersSatsTotal
          )
          toast(
            <SuccessfulTransactionToast
              message='Buy Order Broadcasted'
              transactionId={buyResult.txId ?? ''}
            />,
            { toastId: buyResult.txId ?? Date.now() }
          )

          resetForm()
          onClose({ filledOrders: ordersToBuy })
        } else {
          GA_AbandonSwap(
            window,
            gaEventId,
            rune.runeName,
            runesAmountTotal,
            'buy',
            selectedNetworkFee,
            ordersTotals.ordersSatsTotal
          )
          setGaEventId('')
          setError(buyResult.error)
        }
      }
    } catch (error: any) {
      console.error(error)
      setError(error.message)
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    GA_AbandonSwap(
      window,
      gaEventId,
      rune.runeName,
      runesAmountTotal,
      'buy',
      selectedNetworkFee,
      ordersTotals.ordersSatsTotal
    )
    setGaEventId('')
    setError(undefined)
    onClose({ validOrders })
  }

  const priceSats = calculateWeightedAveragePrice({ orders: filteredOrders })

  const buttonText = loadingText ?? 'Confirm Buy'
  const notEnoughBalance = btcBalance < ordersTotals.btcTotal
  const noValidOrders = validOrders.length === 0
  const someOrdersNotAvailable = validOrders.length < orders.length

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Buy {formatRuneName(rune)}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Average Price</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={
                <>
                  &nbsp;
                  <PriceSatsDisplay priceSats={priceSats} runeSymbol={rune.runeSymbolChar} />
                </>
              }
              subContent={<PriceUsdDisplay priceSats={priceSats} />}
              loading={isTotalsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>{formatRuneName(rune)}</DrawerBoldColumn>
          <RunesTotalColumn>
            +
            <RunesAmountDisplay
              rune={rune}
              runesAmount={runesAmountTotal}
              showPriceSymbol={false}
            />
          </RunesTotalColumn>
        </DrawerRow>

        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>
            <AccordianHeader
              linkText='Total BTC'
              expanded={isOrderDetailsExpanded}
              onExpand={() => setIsOrderDetailsExpanded(!isOrderDetailsExpanded)}
            />
          </DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={<BtcTotal>-{ordersTotals.btcTotal}</BtcTotal>}
              subContent={<UsdTotal>&nbsp;{formatUsd({ usd: ordersTotals.usdTotal })}</UsdTotal>}
              loading={isTotalsLoading}
            />
          </DrawerColumn>
        </DrawerRow>

        <div ref={animateRefParent}>
          <AccordionContentWrapper expanded={isOrderDetailsExpanded}>
            <DrawerRow>
              <DrawerBoldColumn>Order Cost</DrawerBoldColumn>
              <DrawerColumn>
                <DrawerTieredContent
                  content={
                    <BtcTotal>
                      -{`${ordersTotals.ordersBtcTotal}`}{' '}
                      <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                    </BtcTotal>
                  }
                  subContent={
                    <UsdTotal>&nbsp;{formatUsd({ usd: ordersTotals.ordersUsdTotal })}</UsdTotal>
                  }
                  loading={isTotalsLoading}
                />
              </DrawerColumn>
            </DrawerRow>
            {ordersTotals.marketplaceFeeAmountBtcTotal > 0 && (
              <DrawerRow>
                <DrawerBoldColumn>Service Fee</DrawerBoldColumn>
                <DrawerColumn>
                  <DrawerTieredContent
                    content={
                      <BtcTotal>
                        -
                        {formatNumber({
                          numStr: `${ordersTotals.marketplaceFeeAmountBtcTotal}`,
                          showAllDecimals: true,
                        })}{' '}
                        <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                      </BtcTotal>
                    }
                    subContent={
                      <UsdTotal>
                        &nbsp;
                        {formatUsdPrice({ price: ordersTotals.marketplaceFeeAmountUsdTotal })}
                      </UsdTotal>
                    }
                    loading={isTotalsLoading}
                  />
                </DrawerColumn>
              </DrawerRow>
            )}
            <DrawerRow>
              <DrawerBoldColumn>Estimated Network Fee</DrawerBoldColumn>
              <DrawerColumn>
                <DrawerTieredContent
                  content={
                    <BtcTotal>
                      -{`${ordersTotals.networkFeeBtcTotal}`}{' '}
                      <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                    </BtcTotal>
                  }
                  subContent={
                    <UsdTotal>
                      &nbsp;{formatUsdPrice({ price: ordersTotals.networkFeeUsdTotal })}
                    </UsdTotal>
                  }
                  loading={isTotalsLoading}
                />
              </DrawerColumn>
            </DrawerRow>
          </AccordionContentWrapper>
        </div>
      </DrawerCard>
      {/* <DrawerCard>
          <DrawerRow>
            <DrawerBoldColumn>Available BTC</DrawerBoldColumn>
            <DrawerColumn>
              <DrawerColumn>
                {btcBalance} <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
              </DrawerColumn>
              <UsdTotal>{formatUsd(btcBalanceUsd)}</UsdTotal>
            </DrawerColumn>
          </DrawerRow>
        </DrawerCard> */}
      {!noValidOrders && (
        <DrawerCard>
          <Accordion linkText='View Orders'>
            <VirtualizedTableWrapper
              columns={[
                {
                  dataKey: 'runesAmount',
                  label: isMobile ? 'Qty' : 'Quantity',
                  formatter: ({ data: order }) =>
                    formatRunesAmount({
                      rune,
                      runesAmount: order.runesAmount,
                    }),
                },
                {
                  dataKey: 'priceSats',
                  label: isMobile
                    ? 'Price'
                    : `Price (${formatPriceSymbol({ runeSymbol: rune.runeSymbolChar })})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
                  ),
                },
                {
                  dataKey: 'satsAmount',
                  label: `Total (${BTC_PRICE_SYMBOL})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell
                      header={`${formatBtc(order.satsAmount)}`}
                      subHeader={formatUsd({ usd: order.valueUsd })}
                    />
                  ),
                },
              ]}
              paginatedData={[filteredOrders]}
              fetchPage={async () => {}}
              hasNextPage={false}
              viewableRows={filteredOrders.length}
              rowHeight={30}
            />
          </Accordion>
          {someOrdersNotAvailable && (
            <WarningMessage message={'Some of your selected orders are no longer available'} />
          )}
        </DrawerCard>
      )}

      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            selectedFee={selectedNetworkFee}
            onChange={(fee) => setSelectedNetworkFee(fee)}
          />
        </DrawerRow>
      </DrawerCard>

      {notEnoughBalance && (
        <ErrorMessage
          message={`Not enough BTC balance. Available: 
              ${formatNumber({ numStr: `${btcBalance}` })}
              ${BTC_PRICE_SYMBOL}`}
        />
      )}
      {noValidOrders && <ErrorMessage message={'None of your selected orders are available'} />}
      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => setLoadingText(undefined)}>
            Cancel Tx
          </DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          disabled={notEnoughBalance || noValidOrders || isTotalsLoading || DISABLE_WRITES}
          loading={!!loadingText}
          onClick={onConfirmClick}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const RunesTotalColumn = styled(DrawerColumn)`
  gap: 0px;
  display: flex;
  color: ${COLORS.positive};
  font-weight: 700;
`

const BtcTotal = styled(DrawerColumn)`
  display: flex;
  color: ${COLORS.negative};
  font-weight: 700;
`

const UsdTotal = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-size: 14px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<Order>)`
  tfoot {
    display: none;
  }
  thead {
    position: relative !important;
  }
`

const AccordionContentWrapper = styled(AccordionContent)`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  gap: 5px;
`
