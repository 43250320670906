import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { buildAccountDetailsUrl } from 'src/pages'
import { formatShortAddress } from 'src/wallet'
import { COLORS } from 'src/shared/constants'
import { BUTTON_HOVER_STYLES } from 'src/shared/components'
import { GA_ViewAccount } from 'src/shared/utils/analytics'

interface Props {
  address: string
  displayName?: string
  displayShortAddress?: boolean
  className?: string
}

export function AccountDetailsLink({
  address,
  displayName,
  displayShortAddress = true,
  className,
}: Props) {
  return (
    <LinkWrapper to={buildAccountDetailsUrl(address)} className={className} onClick={() => GA_ViewAccount(window)}>
      <Address>
        {displayName ?? displayShortAddress ? formatShortAddress(address) : address}
      </Address>
    </LinkWrapper>
  )
}

const LinkWrapper = styled(Link)`
  ${BUTTON_HOVER_STYLES}
`

const Address = styled.div`
  font-weight: 700;
  color: ${COLORS.link} !important;
`
